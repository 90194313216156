import {
  CircularProgress as NonBlockingLoader,
  Typography
} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import * as Sentry from '@sentry/browser';
import FBUser from "fitbits/user";
import {
  bffAddCustomer2Company,
  bffMailWelcomeCustomer, bffUpdateHubspot, bffUpdateHubspotProp
} from "fitbud/api";
import Confirmation from "fitbud/components/confirmationDialog";
import LoadingIndicator from "fitbud/components/LoadingIndicator";
import firebase from "fitbud/firebase";
import NoQuestionnaire from "fitbud/images/no_quest.svg";
import Resend from "fitbud/images/resend.svg";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import appRdxFns from "fitbud/redux/app";
import browseusrRdxFns from "fitbud/redux/browseUsersList";
import chatusrRdxFns from "fitbud/redux/chatList";
import chekinusrRdxFns from "fitbud/redux/checkinsList";
import csusrRdxFns from "fitbud/redux/completingSoonList";
import ptausrRdxFns from "fitbud/redux/plansToAssignList";
import ptuusrRdxFns from "fitbud/redux/plansToUpdateList";
import selectedScheduleRdxFns from "fitbud/redux/selectedSchedule";
import tagsRdxFns from "fitbud/redux/tags";
import companies from "fitbud/repo/companies";
import usrRepo from "fitbud/repo/users";
import {
  DEFAULT_ERROR, DEFAULT_UNIT_SYSTEM, DUPLICATE_USER, EDIT_PLAN_EXTENSION, ERROR_DECIMAL_DURATION, MAX_USER_INVITE, ERROR_USER_ADD, HUBSPOT_PROPS, oprtnlDateFormat, SCHEDULE_MAX_VALID_DAYS, SCHEDULE_MIN_VALID_DAYS, SCHED_MAX_DAY_ERR, SCHED_MIN_DAY_ERR
} from "fitbud/utils/constants";
import { userPlanPath } from "fitbud/utils/helpers";
import ChatView from "fitbud/views/chats/chatView";
import PageNotFound from "fitbud/views/pageNotFound";
import {
  durationInTypes, multiplier
} from "fitbud/views/schedulePlanner/helper";
import ChangePlanDates from "fitbud/views/users/changePlanDates";
import CreateEditForm from "fitbud/views/users/createEditForm";
import {
  fetchTags,
  findTimeSpan
} from "fitbud/views/users/subComponents";
import update from 'immutability-helper';
import _ from "lodash";
import moment from "moment";
import { withSnackbar } from "notistack";
import React, { Component, createContext, useContext } from "react";
import { connect } from "react-redux";
import { getTrueDuration, hasLength } from "../../utils/helpers";
import CheckIns from "./checkIns/index";
import Header from "./header";
import { getUserStatus } from "./helpers";
import NavBar from "./navBar";
import Overview from "./overview";
import EditProfileDialog from "./overview/editProfileDialog";
import Profile from "./profile";
import Progress from "./progress/index";
import ProgressTrendsProvider from "./progTrendsProvider";
import { UserSchProvider, UserSchViewer } from "fitbud/views/schedulev2";
import Notes from "./userNotes";
import NotesDrawer from './overview/notesDrawer';
import ProfileDrawer from "./overview/profileDrawer";
import ChangeEmailDialog from "./changeEmailDialog";
import {getPlanAssignPurchase} from "./helpers";
import { getReminderTags } from "fitbud/utils/services";

const USER_SIGN_UP_CONSTANTS = {
  unit_system: DEFAULT_UNIT_SYSTEM,
  total_checkins: 0,
  total_messages: 0,
  enable_chat: true,
  aplan: null,
  current_plan_status: "inactive",
  defaults: {
    glass: {
      volume: 250
    }
  }
};

const x = {
  welcome: {
    msg: 'Resend welcome mail',
    handler: (cid, uid) => bffMailWelcomeCustomer(false, cid, uid),
  },
};

export const UserContext = createContext({});
export const useUserContext = () => useContext(UserContext);

const fetchQuestionnaires = async (cid) => {
  const snapshot = await firebase
    .firestore()
    .collection(`companies/${cid}/questionnaire`)
    .doc("sample")
    .get();
  if(snapshot.exists) return snapshot.data();
  return null;
};

class UserDetails extends Component {
  static contextType = FirebaseAuthContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isValidId: true,
      userDoc: {},
      fbUser: {},
      view: "overview",
      planId: undefined,
      planDoc: {},
      measurableTags: {},
      measurableUnits: {},
      selectedWeek: null,
      weeks: {},
      errors: {},
      woAggregate: {},
      editUser: false,
      openChangeClientEmail: false,
      isReassignPlanFormOpen: false,
      isActivateConfirmationOpen: false,
      weekLoading:false,
      checkins: [],
      packDoc: [],
      pricingDoc: {},
      isPlanChange: false,
      checkinsAggregate: [],
      scheduleAggrs:{},
      measurementsAggregate:[],
      isPlanExtend: false,
      clickExtendPlanButton: false,
      appConfig: {},
      companyDefaultValue: {},
      companyBMR: {},
      extend: false,
      sessionDocs: [],
      isVideoCalling: false,
      isScheduleLoading: true,
      noteDocs:[],
      noMoreCalls:false,
      noMoreNotes:false,
    };
  }
  get docId() {
    return this.props.id;
  }

  get planId () {
    let {userDoc} = this.props
    return _.get(userDoc, "aplan");
  }

  get isNew() {
    return this.props.id === "new";
  }
  
  get check_in_tag_config (){
    return _.get(this.state, "reminder_tag_config.checkins", {});
  }

  
  toggleChangeClientEmail = () => {
    this.setState(s => ({...s, openChangeClientEmail: !s.openChangeClientEmail}));
  };

  toggleFlag = () => {
    const flagged = (this.props.userDoc.systags || []).includes('flagged');
    firebase.firestore().doc(`user_profiles/${this.docId}`)
      .update({
        systags: flagged ? firebase.firestore.FieldValue.arrayRemove('flagged') : firebase.firestore.FieldValue.arrayUnion('flagged'),
        _muat: firebase.firestore.FieldValue.serverTimestamp(),
        _uat: firebase.firestore.FieldValue.serverTimestamp(),
      }).then(() => {
        this.props.setUser({...this.props.userDoc, systags: flagged ? [] : ['flagged']});
      }).catch(console.error);
  };

  changeView = (e, val) => {
    const { checkins } = this.state
    if(val === 'schedule' && !this.state.userScheduleLoaded){
      this.setState({ isScheduleLoading: true })
      this.loadUserScheduleData(this.planId, this.props.userDoc, false);
    }
    //Checked for existing data if user clicks on Check Ins. If there is no data, fetching from the database. Need to make call because tag (first checkin) gets change after click on check ins
    if(val === "progress" && checkins.length < 1) {
      this.loadCheckinsWAggregate(this.planId);
    }
    this.setState({ view: val });
  };
  handleChange = e => {
    if (!e) return;
    let chng = {};
    if (!e.target) {
      chng = e;
    }else if (e.target.id === "email" || e.target.id === "name") {
      chng = {
        [e.target.name || e.target.id]: e.target.value.trim()
      };
    } else chng = { [e.target.name || e.target.id]: e.target.value };
    this.setState(s => {
      const out = { doc: { ...s.doc, ...chng } };
      if (!!chng.duration)
        out.errors = {...(s.errors || {}), duration: null}
      return out;
    });
  };

  handleChangeWrapper = e => {
    const { value } = e.target;
    const { packDoc, doc } = this.state;
    let pricingDoc = [];
    let vid_call = null;
    //If user selects custom plan after selecting any other option
    if (value === "custom") {
      this.setState((prev) => ({
        pricingDoc: [{
          id: "custom",
          duration: "",
          duration_type: "weeks",
          ref_name: "Custom",
          title: "Custom",
          mode: "one_time"
        }],
        doc: {
          //in case of custom pack , overriding all doc state except name and email.
          email:doc.email,
          name: doc.name,
          duration: "",
          durationType: "weeks",
          pricingDoc: {},
          id: "custom",
          ref_name: "custom",
          title: "custom",
          mode: "one_time"
        },
        errors: {...(prev.errors || {}), duration: null},
        isPlanChange: true,
      }));

      return this.handleChange(e);
    }

    const [id, pack_id] = value.split(',');
    if (pack_id) {
      const plan = _.find(packDoc, ['pack_id', pack_id]);
      if (plan) {
        if(plan.vid_call && this.state.isVideoCalling) vid_call = plan.vid_call
        const match = _.find(plan.price_opts, ['id', id]);
        if (match) {
          pricingDoc.push({
            ...match,
            ref_name: plan.ref_name,
            title: plan.title,
            pack_id,
          });
        } else {
          pricingDoc.push({
            id: 'custom',
            duration: '',
            duration_type: 'weeks',
            mode: 'one_time',
            ref_name: plan.ref_name,
            title: plan.title,
            pack_id,
          });
        }
      }
    } else {
      for (let i = 0; packDoc && packDoc.length && i < packDoc.length; i++) {
        const plan = packDoc[i];
        const match = _.filter(plan.price_opts, ['id', value]);
        if (match && match.length > 0) {
          if(plan.vid_call && this.state.isVideoCalling) vid_call = plan.vid_call
          pricingDoc.push({
            ...match[0],
            ref_name: plan.ref_name,
            title: plan.title,
          });
          break;
        }
      }
    }
    if (pricingDoc.length < 1) return; // something went wrong and match wasn't found
    const chng = {
      duration: _.get(pricingDoc[0], "duration"),
      durationType: _.get(pricingDoc[0], "duration_type"),
      pricingDoc: { pack: pricingDoc[0] },
      vid_call
    };
    this.setState(s => ({ doc: { ...s.doc, ...chng }}));
    this.setState({
      pricingDoc: pricingDoc,
      errors: {...(this.state.errors || {}), duration: null},
      isPlanChange: true,
    });
    this.handleChange(e);
  }

  validate = () => {
    let { doc} = this.state;
    let {userDoc} = this.props;
    let docToValidate = {};
    if (!doc) doc = {};
    if (this.isNew) {
      let durationType = doc["durationType"] || "weeks";
      docToValidate = {
        ...doc,
        durationType
      };
      if (!!docToValidate["duration"]) {
        docToValidate["duration"] =
          multiplier(durationType) * Number(docToValidate["duration"]);
      }
    } else {
      let updatedDurationType = doc["durationType"] || userDoc["durationType"] || "weeks";

      let updateDurationInDays = this.setUpdatedDurationOnEdit({
        updatedDurationType
      });
      docToValidate = {
        duration: updateDurationInDays,
        durationType: updatedDurationType,
        name: "name" in doc ? doc["name"] : userDoc["profile"]["name"],
        email: userDoc["profile"]["email"]
      };
    }
    let errors = {},
      flag = 1;
    let requiredFields = ["name", "duration", "email"];
    requiredFields.forEach(i => {
      if (
        !docToValidate[i] ||
        (docToValidate[i] &&
          typeof docToValidate[i] === "string" &&
          !docToValidate[i].trim())
      ) {
        errors[i] = ERROR_USER_ADD(i);
        flag = 0;
      }
    });
    if (!errors["email"]) {
      // eslint-disable-next-line no-useless-escape
      let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(String(docToValidate["email"]).toLowerCase())) {
        errors["email"] = "The email address you entered is invalid";
        flag = 0;
      }
    }
    
    if (!errors["duration"]) {
      if (parseInt(docToValidate["duration"]) !== docToValidate["duration"]) {
        errors["duration"] = ERROR_DECIMAL_DURATION;
        flag = 0;
      } else {
        if (Number(docToValidate["duration"]) < SCHEDULE_MIN_VALID_DAYS) {
          errors["duration"] = SCHED_MIN_DAY_ERR();
          flag = 0;
        } else if (
          Number(docToValidate["duration"]) > SCHEDULE_MAX_VALID_DAYS
        ) {
          errors["duration"] = SCHED_MAX_DAY_ERR();
          flag = 0;
        }
      }
    }
    this.setState({ errors });
    return flag;
  };
  setUpdatedDurationOnEdit = ({
    userDoc = this.props.userDoc || {},
    doc = this.state.doc || {},
    updatedDurationType
  }) => {
    let updateDurationInDays = Number( _.get(userDoc, "duration"));
    if ( _.get(doc, "durationType") && !("duration" in doc)) {
      updateDurationInDays =
        multiplier(updatedDurationType) *
        Number(durationInTypes( _.get(userDoc, "duration"), _.get(userDoc, "durationType")));
    } else if ("duration" in doc) {
      updateDurationInDays =
        multiplier(updatedDurationType) * Number( _.get(doc, "duration"));
    }
    return updateDurationInDays;
  };
  //TODO :: need to verify
  onSubmit = async e => {
    e.preventDefault();
    if (!this.validate()) return;
    this.props.showLoader();
    const { onSave, enqueueSnackbar } = this.props;
    if (this.isNew) {
      // Create new user
      let {
        email,
        name,
        duration,
        startDate = moment(),
        durationType = "weeks",
        pricingDoc,
        vid_call,
      } = this.state.doc || {};

      if (!pricingDoc || !pricingDoc.pack) {
        pricingDoc = {pack: {
          id: "custom",
          pack_id: "custom",
          duration: multiplier(durationType) * Number(duration),
          duration_type: durationType,
          ref_name: "Custom",
          title: "Custom",
          mode: "one_time"
        }};
      }else{
        pricingDoc = {pack: {
          ...pricingDoc.pack,
          duration: multiplier(durationType) * Number(duration),
          duration_type: durationType,
        }}
      }
      let profile = {
        name: name.trim(),
        email: email.trim()
      };
      let durationInDays = multiplier(durationType) * Number(duration);
      let endDate = moment(startDate)
        .add(durationInDays - 1, "days")
        .format(oprtnlDateFormat);
      try {
        const userToken = await this.props.authUser.getIdToken();
        const { data } = await bffAddCustomer2Company(userToken, this.context.cid, email);
        const { success, message, profile_id, is_owner } = data;
        if (!success) throw new Error(message);
        const docRef = await firebase
          .firestore()
          .collection("user_profiles")
          .doc(profile_id);
        let user_measurements = this.props.measurableTags;
        let out = {
          profile,
          duration: durationInDays,
          startDate: moment(startDate).format(oprtnlDateFormat),
          endDate: endDate,
          durationType,
          archive: false,
          blocked: false,
          ...USER_SIGN_UP_CONSTANTS,
          measurements: user_measurements,
          [this.context.cid]: {
            read_count: 0,
            last_seen: new Date(),
            checkin_read_count: 0
          }
        };
        out.plan_assign = pricingDoc;
        out.purchases = {plan_assign: {...getPlanAssignPurchase(pricingDoc.pack, vid_call || null, startDate)}}
        await docRef.update(out);
        // HUBSPOT - Track new user created - client_added, client_plan_assigned props
        bffUpdateHubspot({
          [HUBSPOT_PROPS.CLIENT_ADDED]: moment().format('YYYY-MM-DD'), 
          [HUBSPOT_PROPS.CLIENT_PLAN_ASSIGNED]: moment().format('YYYY-MM-DD'),
        });
        this.setState({ endDate, ...profile, doc: {} });
        const docSnap = await docRef.get();
        const { uid, cid } = docSnap.data();
        if (!is_owner) {
          bffMailWelcomeCustomer(userToken, cid, uid)
        }
        this.props.insertbrowseUsersList({
          _id: docSnap.id,
          data: {
            name,
            email,
            checkin_read_count: 0,
            cid: this.context.cid
          }
        });
        if (!!onSave) onSave(docSnap);
        this.props.hideLoader();
      } catch (err) {
        const errcode = err.response && err.response.data.code;
        let error = DEFAULT_ERROR;
        if (errcode === 'DUPLICATE') error = DUPLICATE_USER;
        else if (errcode === 'UPGRADE') error = MAX_USER_INVITE;
        this.props.hideLoader();
        enqueueSnackbar(error, { variant: "error" });
        Sentry.captureException(err);
      }
    } else {
      // Edit a user
      let { doc } = this.state;
      let {userDoc} = this.props;
      if (!doc) doc = {};

      let updatedDoc = {},
      updatedStartDate ; // Store updated information
      if (userDoc.currPurchase) {
        // If user has current purchase only change startDate and endDate
        updatedStartDate = doc["startDate"]
          ? moment(doc["startDate"]).format(oprtnlDateFormat)
          : userDoc["startDate"];
        let updateEndDate = moment(updatedStartDate || moment().format(oprtnlDateFormat), oprtnlDateFormat)
          .add(userDoc.duration - 1, "days")
          .format(oprtnlDateFormat);
        updatedDoc = {
          profile: {
            ...userDoc["profile"],
            name: doc["name"] ? doc["name"].trim() : userDoc["profile"]["name"].trim(),
          },
          startDate: updatedStartDate,
          endDate: updateEndDate,
          archive: false,
          blocked: false,
          deactivated: false,
          deactivatedOn: null
        };
      } else {
        let updatedDurationType = doc["durationType"] || userDoc["durationType"] || "weeks";
        updatedStartDate = doc["startDate"]
          ? moment(doc["startDate"]).format(oprtnlDateFormat)
          : userDoc["startDate"];
        let updateDurationInDays = this.setUpdatedDurationOnEdit({
          updatedDurationType
        });
        let updateEndDate = moment(updatedStartDate || moment().format(oprtnlDateFormat), oprtnlDateFormat)
          .add(updateDurationInDays - 1, "days")
          .format(oprtnlDateFormat);
        updatedDoc = {
          profile: {
            ...userDoc["profile"],
            name: doc["name"]
             ? doc["name"].trim()
             : userDoc["profile"]["name"].trim()
          },
          duration: updateDurationInDays,
          durationType: updatedDurationType,
          startDate: updatedStartDate || moment().format(oprtnlDateFormat),
          endDate: updateEndDate,
          archive: false,
          blocked: false,
          deactivated: false,
          deactivatedOn: null
        };

        if (doc["pricingDoc"] && !!doc["pricingDoc"].pack) {
          updatedDoc.plan_assign = {pack: {
            ...doc["pricingDoc"].pack,
            duration: multiplier(doc["pricingDoc"].pack.duration_type) * Number(doc["pricingDoc"].pack.duration),
          }};
        } else if (!!userDoc["plan_assign"]) {
          updatedDoc.plan_assign = {pack: {
            ...userDoc["plan_assign"].pack,
            duration: Number(updateDurationInDays),
            duration_type: updatedDurationType,
          }}
        } else {
          updatedDoc.plan_assign = {pack: {
              id: "custom",
              duration: Number(updateDurationInDays),
              duration_type: updatedDurationType,
              ref_name: "Custom",
              title: "Custom",
              mode: "one_time",
            }};
        }
        if (!!userDoc["aplan"]) {
          let assigned_till_diff = moment(
            updatedStartDate,
            oprtnlDateFormat
          ).diff(moment(userDoc["startDate"], oprtnlDateFormat), "days");
          updatedDoc["assigned_till"] = doc["startDate"]
            ? moment(userDoc["assigned_till"], oprtnlDateFormat)
                .add(assigned_till_diff, "days")
                .format(oprtnlDateFormat)
            : userDoc["assigned_till"];
        }
      }

      Promise.all([
        this.updateUserDoc(updatedDoc),
        this.updatePlanStartDate({ startDate: updatedStartDate })
      ])
      .then(async ([userResp, planResp]) => {
          this.props.hideLoader();
          this.props.setUser(userResp.data())
          this.props.setPlan(planResp ? planResp.data() : {})
          this.setState({
            editUser: false,
            // userDoc: userResp.data(),
            // planDoc: planResp ? planResp.data() : {},
            doc: {},
            isPlanChange: false
          });
          this.updateReduxLists(userResp);
        })
        .catch(err => {
          this.props.hideLoader();
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    }
  };

  handleResendEmail = () => {
    const {
      enqueueSnackbar,
      hideLoader,
      showLoader,
      userDoc
    } = this.props;
    showLoader()
    x.welcome.handler(userDoc.cid, userDoc.uid).then(() => {
      enqueueSnackbar("Email sent successfully", { variant: 'success' });
    }
    ).catch(e => {
      console.error(e);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }).finally(() => hideLoader());
  }

  deleteItem = async () => {
    const {
      onDelete,
      enqueueSnackbar,
      hideLoader,
      showLoader,
      userDoc,
      deletebrowseUsersList
    } = this.props;
    showLoader();
    let updatedDoc = { ...userDoc };
  
    const userRef = await firebase
      .firestore()
      .collection("user_profiles")
      .doc(this.docId);
  
    updatedDoc = update(updatedDoc, {
      archive: {
        $set: true
      }
    });
  
    await userRef.update(updatedDoc)
    let doc = await userRef.get();
    if (doc) {
      hideLoader();
      deletebrowseUsersList(doc.id);
      if (!!onDelete) onDelete();
      enqueueSnackbar("Client deleted successfully.", { variant: "success" });
    }
  }

  updateReduxLists = snapshot => {
    [
      "browseUsersList",
      "chatUsersList",
      "checkinsUsersList",
      "ptuUsersList",
      "ptaUsersList",
      "csUsersList"
    ].forEach((list, i) => {
      let currentList = this.props[list];
      let userFound;
      let userFoundInSearchList;
      userFound =
        currentList &&
        currentList["docs"] &&
        currentList["docs"].find(i => i._id === snapshot.id);
      if (
        !userFound &&
        currentList &&
        currentList["searchResult"] &&
        currentList["searchResult"].length
      ) {
        userFoundInSearchList = currentList["searchResult"].find(
          i => i._id === snapshot.id
        );
      }
      if (!!userFound || !!userFoundInSearchList) {
        let updatedUser = !!userFound
          ? { ...userFound }
          : { ...userFoundInSearchList };
        if (updatedUser["data"]["profile"]) {
          updatedUser["data"]["profile"]["name"] = snapshot.data()["profile"][
            "name"
          ];
        } else {
          updatedUser["data"]["name"] = snapshot.data()["profile"]["name"];
        }
        updatedUser["data"]["tags"] = snapshot.data()["tags"]
        this.props[`update${list}`](updatedUser);
      }
    });
  };
  updateUserDoc = async updatedDoc => {
    let docRef = firebase
      .firestore()
      .collection("user_profiles")
      .doc(this.docId);
    await docRef.set(updatedDoc, { merge: true });
    let data = await docRef.get();
    return data;
  };
  updatePlanStartDate = async startDate => {
    if (!!this.planId && this.docId) {
      const planRef = firebase
        .firestore()
        .doc(userPlanPath(this.docId, this.planId));
      await planRef.set(startDate, { merge: true });
      let data = await planRef.get();
      return data;
    }
    return null;
  };
  toggleLoading = (val = false) => {
    this.setState({ loading: val });
  };

  fetchPackDoc = async () => {
    const snapshot = await firebase
        .firestore()
        .collection(`/companies/${this.context.cid}/packs`).get();
      return snapshot.docs;
  }

  fetchMeasurements = async () => {
    if (!this.props.companyData["profile"]) {
      return companies.doc(this.context.cid);
    }
    return null;
  };
  fetchTagDoc = async () => {
    if (
      !this.props.measurableTags ||
      _.entries(this.props.measurableTags).length === 0
    ) {
      return fetchTags();
    }
    return null;
  };

  fetchReminderTagDoc = async () =>{
    return getReminderTags();
  };

  fetchConfigDoc = async () => {
    if (
      !this.props.measurableUnits ||
      _.entries(this.props.measurableUnits).length === 0
    ) {
      const snapshot = await firebase
        .firestore()
        .collection("config")
        .doc("units")
        .get();

      return snapshot.data();
    }
    return null;
  };

  fetchSessions = async (refetch=false, lastDoc, updateLoader, forceReload) => {
    const { sessionDocs, noMoreCalls } = this.state;
    const N = Math.max(5, Math.ceil((window.innerHeight - 60) / 120) + 2);
    if(noMoreCalls && updateLoader)return updateLoader(false);
    const uid = (this.docId.indexOf(':') > 0) ? this.docId.split(":") : this.docId;
    const docRef = firebase.firestore().collection(`companies/${this.context.cid}/bookings`).where(`uid`, "==", uid[1]);
    let snapshot = false;
    if((!refetch || !sessionDocs || sessionDocs.length === 0 || !lastDoc || !!forceReload)){
      // no data is there in state load fresh
      snapshot = await docRef.orderBy('time', 'desc').limit(N).get();
    } else {
      //load more notes
      snapshot = await docRef.where('time', '<', lastDoc.data().time.toDate()).orderBy('time', 'desc').limit(N).get();
    }
    if(!snapshot || snapshot.empty){
      if(updateLoader) updateLoader(false);
      this.setState({noMoreCalls: true});
    }
    let updatedSessionDocs = _.cloneDeep(!refetch ? [...snapshot.docs] :[...sessionDocs, ...snapshot.docs]);
    this.setState({ sessionDocs: updatedSessionDocs });
    if(!!updateLoader) updateLoader(false);
  };


  fetchNotes = async (refetch=false, lastDoc, updateLoader, forceLoad=false) => {
    const { noteDocs, noMoreNotes } = this.state;
    const N = Math.max(5, Math.ceil(window.innerHeight / 120));
    if (updateLoader) updateLoader(false);
    if (forceLoad) this.setState({ noMoreNotes: false });
    else if (noMoreNotes) {
      if (updateLoader) updateLoader(false);
      return; 
    }
    const docRef = firebase.firestore().collection(`user_profiles/${this.docId}/notes`);
    let snapshot = false;
    if((!refetch || !noteDocs || noteDocs.length === 0 || !lastDoc)){
      // no data is there in state load fresh
      snapshot = await docRef.orderBy('ts', 'desc').limit(N).get();
    } else {
      //load more notes
      snapshot = await docRef.where('ts', '<', lastDoc.data().ts.toDate()).orderBy('ts', 'desc').limit(N).get();
    }
    if(!snapshot || snapshot.empty){
      if(updateLoader) updateLoader(false);
      this.setState({noMoreNotes: true});
    }
    let updatedNoteDocs = !refetch ? [...snapshot.docs] : [...noteDocs, ...snapshot.docs];
    if(updateLoader) updateLoader(false);
    this.setState({ noteDocs: updatedNoteDocs });
  };

  parseScheduleWeek = (docs) => {
    let out = []
    docs.forEach(doc => {
      let data = doc.data();
      out.push(data);
    })
    return out;
  }

  parsePackData(docs) { // FIXME use parsePacks from fitbud/views/plan/helper instead
    if(!docs) return null;
    const out = [];
    docs.forEach(doc => {
      const id = doc.id;
      const data = doc.data();
      if (data.archive) return;
      out.push({pack_id: id, ...data});
    });
    return out;
  };

  async fetchReminderConfig  (){
    const ref = firebase.firestore().doc(`/companies/${this.context.cid}/misc/app-reminders`);
    const snap = await ref.get();
    const res = snap.data();
    return res || {};
  }


  async componentDidMount() {
    this.toggleLoading(true);
    try{
      const st = window.performance.now();
      let tagDoc = await this.fetchTagDoc();
      let companyDoc = this.props.companyDoc || undefined;
      let packDoc = await this.fetchPackDoc();
      const reminderTagConfig = await this.fetchReminderTagDoc();
      const {company_config : reminderConfig, company_prefs = {}} = await this.fetchReminderConfig(); //TODO: do i need to fetch this or use aggregate reminder company setting value:
      if(!!packDoc) {
        let packParsedData  = this.parsePackData(packDoc);
        this.setState({
          packDoc: packParsedData
        })
      }
      if(!!reminderConfig){
        this.setState({
          reminderConfig,
          company_prefs
        })
      }
      if(!!reminderTagConfig){
        this.setState({
          reminder_tag_config : reminderTagConfig
        })
      }
    if(!!companyDoc) {
      const { app_config = null, defaults = null, bmr = null } = companyDoc.data();
      this.setState({appConfig: app_config, companyDefaultValue: defaults, companyBMR: bmr || {},
        isVideoCalling: _.get(companyDoc.data(), 'features.video_calling', false)
      });
    } else {
      let companyDefaultVal = _.get(this.props, "companyData.profile.defaults") || {};
      let companyBMRVal = _.get(this.props, "companyData.profile.bmr") || {};
      let isVideoCalling = _.get(this.props, 'companyData.profile.features.video_calling', false)
      this.setState({companyDefaultValue: companyDefaultVal, companyBMR: companyBMRVal, isVideoCalling });
    }

    if (tagDoc) {
      let companyMeasurement = companyDoc
        ? _.get(companyDoc.data(), "checkin_config.measurements") || {}
        : _.get(
            this.props,
            "companyData.profile.checkin_config.measurements"
          ) || {};
      const body_entities = tagDoc;
      let measurableTags = {};
      for (let i in body_entities) {
        measurableTags[i] = {
          value: body_entities[i].value,
          // optional: !!(companyMeasurement[i] && companyMeasurement[i].optional) ,
          optional: !!companyMeasurement[i]
            ? companyMeasurement[i].optional
            : true,
          unit_type: body_entities[i]["unit_type"],
          tracking: companyMeasurement && i in companyMeasurement,
          priority: companyMeasurement[i]?.priority || null, //setting default priority for tags
        };
      }
      this.props.insertTags(measurableTags);
    }

    if (this.isNew) {
      this.toggleLoading(false);
      return;
    }

    let doc = await usrRepo.doc(this.docId);
    if (!doc) {
      this.toggleLoading(false);
      this.props.enqueueSnackbar("Unable to fetch. Please try later.", {
        variant: "error",
      });
      return;
    }
    if (!doc.exists) {
      this.toggleLoading(false);
      this.setState({ isValidId: false });
      return;
    }
    let userDoc = doc.data();
    this.props.setUser(userDoc);
    let planId = userDoc.aplan;
    const fbUser = await FBUser.init(firebase.firestore(), doc, {compIRD: companyDoc});
    this.setState({
      fbUser
    })
    const reminders = await this.getReminderAggregate(); //get aplan aggregate level reminders value;
    if(!!reminders){
      this.setState({
        reminders:{
          ...reminders
        }
      })
    }
    if (!planId) {
      this.toggleLoading(false);
      this.changeView(null, this.selectTab());
      return;
    }
    let planDoc = await this.getUserScheduleDoc(planId, false);
    if(!!planDoc){
      this.props.setPlan(planDoc.data());
    }
    this.changeView(null, this.selectTab(!!planDoc && planDoc.data()));
    this.toggleLoading(false);
    const end = window.performance.now();
    console.log(`User View Mounting: ${((end - st)/ 1000).toFixed(2)} sec`)
    }catch(err){
      console.log(">> err",err);
      this.props.enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);

    }
  }

  loadCheckinsWAggregate = async(planId, refetch = false) => {
    const start = window.performance.now();
    if((!refetch && this.state.checkinsLoaded)) return;
    this.setState({ isCheckinsLoading: true })
    let promises = [ this.requestCheckins() ];
    if(!!planId) promises.push(this.loadCheckinsAggregate());
    await Promise.all(promises);
    this.setState({
      isCheckinsLoading: false,
      checkinsLoaded: true,
    })
    const end = window.performance.now();console.log(`Checkins Loaded in : ${((end - start ) / 1000).toFixed(1)} sec`);
  }
  saveSchAggrs=(aggrs)=>{
    this.setState({scheduleAggrs:{...aggrs}})
  }
  loadUserScheduleData = async (planId, userDoc, refetch=false)=> {
    if(!refetch && this.state.userScheduleLoaded) return;
    const start = window.performance.now();
    let planDoc = await this.getUserScheduleDoc(planId, refetch);
    if ((!planDoc || !planDoc.exists) && _.isEmpty(this.props.planDoc)) {
      return;
    }
    let planDocData = !planDoc ? { ...this.props.planDoc } : planDoc.data();
    // let checkinDoc = await this.getCheckins(planId);
    // let checkinsAggregateDoc = await firebase.firestore().collection(userPlanPath(this.docId, planId, 'aggregate')).doc("checkins").get();
    
    // this.getAggCheckinsDoc(checkinsAggregateDoc)
    let weekOrder = planDocData.weeks || [];
    // let updatedCheckins = _.cloneDeep(this.updateCheckinsDoc(checkinDoc, refetch));
    
    
    let initialWeek = this.getInitialWeek(userDoc, planDocData);
    let selectedWeekSchedule = await this.loadUserSchedule(weekOrder[initialWeek]);
    let newWeeks = {}
    if(!!selectedWeekSchedule && selectedWeekSchedule.exists){
      selectedWeekSchedule = selectedWeekSchedule.data();
      newWeeks[`week${initialWeek + 1}`] = selectedWeekSchedule;
    }else {
      selectedWeekSchedule = null;
    }
    this.props.setPlan(planDocData);
    this.props.setSchedule(newWeeks)
    this.setState({
      // checkinsLoaded: true,
      // checkins: updatedCheckins,
      userScheduleLoaded: true,
      isScheduleLoading: false,
      // view: this.selectTab(planDocData),
      weekOrder: weekOrder,
      selectedWeek:initialWeek,
      selectedWeekSchedule:selectedWeekSchedule,
    });
    const end = window.performance.now();console.log(`Schedule Loaded in : ${((end - start ) / 1000).toFixed(1)} s`);
    return { newWeeks, planDocData, selectedWeekSchedule, initialWeek, weekOrder, userDoc}
  }

  lastWeekSch = async () => {
    const {weekOrder, selectedWeek, extend, selectedWeekSchedule} = this.state
    let newWeeks = {}
    // get last week schedule by click on extend plan code
    if((selectedWeek !== null && extend && selectedWeekSchedule === undefined)) {
      let getWeekSch = await this.loadUserSchedule(weekOrder[selectedWeek]);
      newWeeks[`week${selectedWeek + 1}`] = getWeekSch.data();
      this.props.setSchedule(newWeeks)
    }
  }

  componentDidUpdate(prevProps, prevState){
    let {weekOrder, selectedWeekSchedule, selectedWeek, view } = this.state;
    let {planDoc, newWeeks, userDoc} = this.props;
    const { userDoc: oldUserDoc } = prevProps;
    let {weeks} = planDoc;
    let outState = {};
    let isPlanStartDate = 'startDate' in planDoc;
    this.lastWeekSch()
    if(hasLength(planDoc) &&  hasLength(userDoc) && !!isPlanStartDate && (selectedWeek === undefined || selectedWeek === null) ){
         let initialWeek = this.getInitialWeek(userDoc, planDoc);
         selectedWeek = initialWeek || 0;
      outState.selectedWeek = initialWeek;
    }
    if(!_.isEqual(weeks, weekOrder )){
      outState.weekOrder = weeks;
    }
    if(!_.isEqual(selectedWeekSchedule,newWeeks[`week${selectedWeek + 1}`] )){
      outState.selectedWeekSchedule = newWeeks[`week${selectedWeek + 1}`]
    }
    if(!!Object.keys(outState).length){
      this.setState(outState)
    }
    if(oldUserDoc.aplan !== userDoc.aplan || 
      oldUserDoc.current_plan_status !== userDoc.current_plan_status){
        //Reset some states when new plan is activated
      this.setState({
        checkinsAggregate: [],
        measurementsAggregate:[],
        userScheduleLoaded: false,
      });
      this.changeView(null, view);
    };
  }

  updateSelectedWeek = async (weekIndex) =>{
    let {weekOrder} = this.state;
    const {newWeeks} = this.props;
    const weekKey = `week${weekIndex + 1}`;
    if (!!newWeeks[weekKey]) {
      this.setState({
        selectedWeek: weekIndex,
        selectedWeekSchedule: newWeeks[weekKey],
      });
    } else {
      try {
        this.setState({weekLoading:true, selectedWeekSchedule:null});
        let selectedWeekDoc = await this.loadUserSchedule(
          weekOrder[weekIndex]
        );
        if (!!selectedWeekDoc && selectedWeekDoc.exists) {
          selectedWeekDoc = selectedWeekDoc.data();
          let out = update(this.state, {
            weekLoading :{
              $set:false
            },
            selectedWeek: {
              $set: weekIndex,
            },
            selectedWeekSchedule: {
              $set: selectedWeekDoc,
            },
          });
          let updatedWeeks = update(newWeeks,{
            [weekKey]: {
              $set: selectedWeekDoc,
            },
          })
          this.props.setSchedule(updatedWeeks)
          this.setState(out);
          this.toggleLoading(false);
        }

      } catch (err) {
        this.setState({weekLoading:false})
        this.props.enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      }
    }
  }

  getInitialWeek = (userDoc, planDoc) =>{
    if (!userDoc || !planDoc || !Object.keys(userDoc).length || !Object.keys(planDoc).length ) return;
    const weekCount = Math.floor(getTrueDuration(planDoc) / 7);
    if(userDoc.current_plan_status === "expired") 
      return getTrueDuration(planDoc) % 7 === 0 ? weekCount - 1  : weekCount;
    let startDate = moment(
      planDoc.startDate,
      oprtnlDateFormat,
      _.get(userDoc, "profile.time_zone")
    )
      .startOf("day")
      .format(oprtnlDateFormat);
    let dateToday = moment
      .tz(_.get(userDoc, "profile.time_zone"))
      .startOf("day")
      .format(oprtnlDateFormat);
    let dateDiffrences = Math.abs(moment(dateToday).diff(startDate, "day"));
    let timeSpanObj = findTimeSpan({
      startDate,
      dateToday,
      duration: getTrueDuration(userDoc),
      dateDiff: dateDiffrences,
    });
    return timeSpanObj ? timeSpanObj.week : 0;
  }

  loadUserSchedule = async (id) =>{
    if (!this.docId || !this.planId || !id) return undefined;
    return firebase
      .firestore()
      .doc(userPlanPath(this.docId, this.planId, 'weeks', id))
      .get();
  }

  updateCheckinsDoc = (checkinDoc, refetch) => {
    let updatedCheckins = refetch ? [] : [...this.state.checkins];
    if(!checkinDoc || !checkinDoc.docs || !checkinDoc.docs.length){
      this.setState({ noMoreCheckins: true });
    } else checkinDoc.docs.forEach(i => updatedCheckins.push(i));
    return updatedCheckins;
  };

  getUserScheduleDoc = (planId, refetch=false) => {
    if (!this.docId || !this.planId || (!refetch && !_.isEmpty(this.props.planDoc))) return undefined;
    return firebase
      .firestore()
      .doc(userPlanPath(this.docId, planId))
      .get();
  };

  getAggregate = (url) => {
    const rest = url.split("/")
    if (rest.length === 5) {
      return firebase.firestore().collection(`/user_profiles/${rest[2]}/checkins`).doc(`${rest[4]}`).get()
    } else {
      return firebase.firestore().collection(`user_profiles/${rest[1]}/checkins`).doc(`${rest[3]}`).get()
    }
  }

  getReminderAggregate = async () =>{
    const snap = await firebase.firestore().doc(`user_profiles/${this.docId}/aggregate/reminders`).get()
    if(snap.exists){
      const data = snap.data();
      return data;
    }
    else return {}
  }

  loadCheckinsAggregate = async() => {
    const path  = userPlanPath(this.docId, this.planId, 'aggregate');
    if(!path) return;
    let doc = await firebase.firestore().collection(path).doc("checkins").get();
    let checkInsDoc = doc.data();
    if(!checkInsDoc) return;
    const checkinAggPhotosArray = [];
    if(checkInsDoc && checkInsDoc.photos){
      Object.keys(checkInsDoc.photos)
      .forEach((item) => !!checkInsDoc.photos[item] && checkinAggPhotosArray.push(item));
    };
    const checkinsDates = checkInsDoc && checkInsDoc.data && Object.keys(checkInsDoc.data);
    const out = [];
    let promises = (checkinsDates && checkinsDates.map(d => {
      const ref = checkInsDoc.data[d];
      if (typeof ref === "string") {
        return this.getAggregate(ref);
      };
      return (ref && ref.get()) || null;
    })) || [];
    const results =  await Promise.all(promises);
    results.forEach(snap => {
      if(!snap) return;
      const data = snap.data();
      if(!!data){
        out.push(data)
      }
    });
    this.setState({
      checkinsAggregate: out,
      checkinAggPhotosArray: checkinAggPhotosArray.sort((a, b) => a - b),
    });
  }

  getCheckins = async planId => {
    let { checkins } = this.state;
    let call = firebase
      .firestore()
      .collection(`user_profiles/${this.docId}/checkins`)
      .orderBy("tag", "desc");
    if (checkins.length) call = call.startAfter(checkins[checkins.length - 1]).limit(5);
    return call.limit(5).get();
  };

  requestCheckins = async updateLoader => {
    if(!!this.state.noMoreCheckins){
      if(updateLoader) updateLoader(false);
      return;
    };
    let checkinDoc = await this.getCheckins(this.planId);
    let updatedCheckins = _.cloneDeep(this.updateCheckinsDoc(checkinDoc));
    if(updateLoader) updateLoader(false);
    this.setState({ checkins: updatedCheckins });
  };

  selectTab = planDoc => {
    const { userPage } = this.props;
    switch (userPage) {
      case "active":
        if (hasLength(planDoc)) return "overview";
        else return "chats";
      case "inactive":
        return "schedule";
      case "new":
        if (hasLength(planDoc) && !!planDoc["plan_state"]) return "overview";
        else return "chats";
      case "checkins": {
        if (!!planDoc) return "progress";//planDoc existence proves a plan was/is assigned, thus checkins are possible.
        else return "overview";
      }
      case "planstoupdate":
        return "schedule";
      case "planstoassign":
        return "schedule";
      case "completingsoon":
        return "schedule";
      case "chats":
        return "chats";
      default:
        return this.state.view;
    }
  };

  checkPlanActivation = () => {
    const { startDate, profile } = this.props.userDoc || {};
    let startOfPlan = moment(startDate, oprtnlDateFormat, profile["time_zone"]);
    let dateToday = moment.tz(profile["time_zone"]).startOf('day');
    if (startOfPlan.isBefore(dateToday, "day")) {
      this.setState({ isActivateConfirmationOpen: true });
    } else {
      this.activatePlan();
    }
  };

  activatePlan = () => {
    this.setState({ isActivateConfirmationOpen: false });
    let {userDoc, planDoc} = this.props;
    const { profile, trainer_id, onboarding_complete } = userDoc;
    if(this.props.tEnabled && !trainer_id) {
      this.props.enqueueSnackbar("Please assign a trainer before activating the plan", { variant: "error" });
      return;
    }
    let updatedDoc = { current_plan_status: "activated" };
    if(!onboarding_complete) {
      //if plan activated, set onboarding, completed and onboarding timestamp
      updatedDoc = {...updatedDoc, onboarding_complete: true,
      questionnaire_timestamp: firebase.firestore.FieldValue.serverTimestamp()}
    }
    const dateToday = moment.tz(profile["time_zone"] || 'UTC').format(oprtnlDateFormat);
    this.props.showLoader();
    let docRef = firebase.firestore()
      .doc(userPlanPath(this.docId, this.planId)); // XXX we are assuming planId can't be null at this point
    let activationDate = dateToday;
    docRef.get().then(planDoc => {
      activationDate = planDoc.data().activationDate || dateToday;
      return Promise.all([
        usrRepo.update(this.docId, updatedDoc),
        docRef.update({plan_state: "activated", activationDate})
      ])
    }).then(([updatedUsrDoc, updatedPlanDoc]) => {
      bffUpdateHubspotProp(HUBSPOT_PROPS.CLIENT_PLAN_ACTIVATED);
      this.props.setUser({
        ...userDoc,
        ...updatedDoc,
      })
      this.props.setPlan({
        ...planDoc,
        plan_state: "activated",
        activationDate
      })
      this.props.hideLoader();
    }).catch(err => {
      this.props.hideLoader();
      this.props.enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    });
  };

  saveWoAggregate = woAggregate => {
    this.setState({ woAggregate });
  };

  isExtendPlanEnable = () =>{
    let {userDoc, planDoc} = this.props;
    let time_zone = _.get(userDoc, "profile.time_zone");
    let subs_duration = _.get(userDoc, "subs_duration");
    let duration = subs_duration || _.get(userDoc, "duration");
    let endDateString = _.get(userDoc, "endDate");
    let startDateString = _.get(planDoc, "startDate");
    if (!startDateString || !duration || !endDateString ) return false;
    let startDate = moment(
      startDateString,
      oprtnlDateFormat,
      time_zone
    ).startOf("day");
    let dateToday = moment.tz(time_zone);
    let dateDiff = Math.abs(moment(dateToday).diff(startDate, "day"))
    let ts = findTimeSpan({
      startDate,
      dateToday,
      duration: duration,
      dateDiff: dateDiff,
    });
    if(ts.timeSpan === "expired" || userDoc.current_plan_status === "expired" ){
      let endDate = moment(
        endDateString,
        oprtnlDateFormat,
        time_zone
      ).startOf("day");
      let end_diff = moment(dateToday).diff(endDate, "days");
      if (Math.abs(end_diff) <= EDIT_PLAN_EXTENSION || end_diff < 0) return true;
      else return false;
    }
    return false;
  }  

  returnProfile = () => {
    const {userDoc} = this.props;
    const {onboarding_response} = userDoc || {}
    if (!this.state.loading && userDoc && !userDoc.onboarding_start_date) {
      return (
        <div className="d-flex justify-content-center align-items-center h-100 flex-column w-50 mx-auto">
          <img src={Resend} alt="resend" />
          <Typography className="font_18_600 fmy-30 text-center">
            Resend welcome email to this client with instructions to install the app.
          </Typography>
          <Button variant="contained" color="primary" className="f-xlarge" onClick={this.handleResendEmail}>
            Resend Email
          </Button>
        </div>
      );
    } 
    const isAnswerAvailable = Object.keys(onboarding_response || {}).length;
    if (userDoc && !!isAnswerAvailable) {
      return (
          <Profile
          {...userDoc}
          docId={this.docId}
          measurableTags={this.props.measurableTags}
          measurableUnits={this.props.measurableUnits}
          unitSystem={this.props.unitSystem}
          unitDetails={this.props.unitDetails}
          cid={this.context.cid}
          // ques={this.props.questions} We need this inside
          // companyBMR={this.state.companyBMR} We need this inside
        />
      );
    } else {
      if (!this.state.loading)
        return (
          <div className="d-flex justify-content-center align-items-center h-100 flex-column">
            <img src={NoQuestionnaire} alt="no questionnaire" />
            <Typography className="font_20_500 fmt-30">
              No Questionnaire Found
            </Typography>
          </div>
        );
    }
  };

  handleResendDialog = () => {
    this.setState({ isActivateConfirmationOpen: false });
    this.handleResendEmail()
  }


  cancelUserForm = () => {
    this.setState({ doc: {}, pricingDoc: {}, isPlanChange: false });
    if (this.isNew) {
      this.props.onCancel();
    } else {
      this.toggleEditUser(false);
    }
  };

  updateUserDoc = (data) => this.props.setUser({ ...this.props.userDoc, ...data });
  toggleEditProfile = (action = true) => this.setState({ editProfileDialog: action });
  toggleNotesDrawer = (action = true) => this.setState({ notesDrawer: action });
  toggleProfileDrawer = async(action = true) => {
    const  { questions } = this.state;
    if(!!action){
      if(!questions || questions.length === 0){
        this.props.showLoader();
        return fetchQuestionnaires(this.context.cid).then((ques)=> {
          if(ques) this.setState({questions: ques.questions});
          this.props.hideLoader();
          this.setState({ profileDrawer: action });
        });
      }
    };
    this.setState({ profileDrawer: action });
  };
  setReminders = (data)=>{ //for storing whole reminders
    this.setState({reminders:data});
  }
  
  refreshUserAndPlanDoc = async () =>{
    const promises = [usrRepo.doc(this.docId), this.getUserScheduleDoc(this.planId, true)];
    await Promise.all(promises).then(([userDoc, planDoc]) => {
      let userData = userDoc.data();
      let planData = planDoc.data();
      this.props.setUser(userData);
      this.props.setPlan(planData);
    });
  };
  
  render() {
    const {
      loading,
      view,
      selectedWOs,
      // weeks,
      editUser,
      checkins,
      isValidId,
      isActivateConfirmationOpen,
      weekOrder,
      packDoc,
      pricingDoc,
      isPlanChange,
      checkinsAggregate,
      sessionDocs,
      doc,
      measurementsAggregate,
      isCheckinsLoading,
      noteDocs,
      editProfileDialog,
      notesDrawer,
      profileDrawer,
      questions,
      checkinAggPhotosArray,
      scheduleAggrs,
      reminders,
      reminderConfig,
      company_prefs
    } = this.state;
    const {userDoc, planDoc, setPlan, setUser } = this.props;
    const status = getUserStatus(userDoc);
    if (!isValidId || userDoc.archive) return <PageNotFound keyName="user" />;
    if (this.isNew) {
      return (
        <>
        <CreateEditForm
          loading={!!this.props.loaderCount}
          isNew={this.isNew}
          doc={doc || {}}
          handleChange={this.handleChange}
          onCancel={this.cancelUserForm}
          onSubmit={this.onSubmit}
          errors={this.state.errors}
          packDoc={packDoc}
          handleChangeWrapper={this.handleChangeWrapper}
          pricingDoc={pricingDoc}
          isPlanChange={isPlanChange}
        />
        {loading && <LoadingIndicator />}
      </>
      );
    }
    if(loading){
      return (
        <div className="w-100 h-100 position-relative bg-light-grey">
          <NonBlockingLoader
            className="position-absolute"
            style={{ top: "50%", left: "50%" }}
          />
        </div>
      );
    };
    // if(isLimitedAccess && isLead) return  <AclBlocker />
    return (
      <UserContext.Provider
        value={{
          selectedWOs: selectedWOs,
          storeWoAggregate: this.saveWoAggregate,
          woAggregate: this.state.woAggregate,
          aplan: (!!userDoc && userDoc.aplan),
          planTotalDuration: (!!userDoc && (userDoc.subs_duration || userDoc.duration)),
          planStartDate: (!!userDoc && userDoc.startDate),
          profile: _.get(userDoc, `profile`, {}),
          userId: _.get(userDoc, `uid`, ''),
          measurableTags: this.props.measurableTags,
          measurableUnits:this.props.measurableUnits,
          unitSystem:this.props.unitSystem,
          unitDetails:this.props.unitDetails,
          userDoc,
          docId: this.docId,
          reminderConfig:reminderConfig,
          company_prefs: company_prefs,
          updateUserDoc: this.updateUserDoc,
          changeView: this.changeView,
          toggleEditProfile: this.toggleEditProfile,
          toggleNotesDrawer: this.toggleNotesDrawer,
          toggleProfileDrawer: this.toggleProfileDrawer,
        }}
      >
        <ProfileDrawer 
          open={profileDrawer}
          onClose={() => this.toggleProfileDrawer(false)}
          questions={questions}
        />
        {editProfileDialog && (
          <EditProfileDialog
            open={editProfileDialog}
            onClose={() => this.toggleEditProfile(false)}
            userDoc={userDoc}
            docId={this.docId}
            updateUserDoc={this.updateUserDoc}
            measurableUnits={this.props.measurableUnits}
            unitSystem={this.props.unitSystem}
            unitDetails={this.props.unitDetails}
          />
        )}
        <NotesDrawer
          refreshNotes={() => this.fetchNotes(false, null, null, true)}
          open={notesDrawer}
          userDoc={userDoc}
          docId={this.docId}
          noteDocs={noteDocs}
          loadNotes={this.fetchNotes}
          onClose={() => this.toggleNotesDrawer(false)}
          loader={{showLoader: this.props.showLoader, hideLoader: this.props.hideLoader}}
        />
        <ProgressTrendsProvider>
        <UserSchProvider uid={this.docId} updateUMS={this.props.updateUMS} userDoc={userDoc} 
          scheduleAggrs={scheduleAggrs}>
          <div className="d-flex flex-column h-100 position-relative">
            <Header
              {...userDoc}
              status={getUserStatus(userDoc)}
              userId={this.docId}
              loading={loading}
              userPage={this.props.userPage}
              toggleEditUser={this.toggleEditUser}
              planExist={!!planDoc && (planDoc["plan_state"] === "activated" || planDoc["plan_state"] === "expired")}
              updateReduxLists={this.updateReduxLists}
              toggleSwitchPlan={this.toggleSwitchPlan}
              planDoc={planDoc}
              setPlan={setPlan}
              setUser={setUser}
              showTags
              module="user"
            />
            <NavBar
              value={view}
              handleChange={this.changeView}
              status={status}
              packDoc={packDoc}
              planDoc={planDoc}
              userDoc={userDoc}
              weeks={weekOrder}
              activateplan={this.checkPlanActivation}
              loading={loading}
              isExtendPlanEnable = {this.isExtendPlanEnable()}
              pricingDoc={pricingDoc}
              userProfileId={this.docId}
              onDelete={this.props.onDelete}
              changeClientEmail={this.toggleChangeClientEmail}
              reminders= {reminders}
              setReminders={this.setReminders}
              reminderConfig={reminderConfig}
              company_prefs={company_prefs}
              check_in_tag_config={this.check_in_tag_config}
              refreshUserAndPlanDoc={this.refreshUserAndPlanDoc}
            />
            <div
              id="container"
              className="overflow-auto position-relative"
              style={{ flex: 1 }}
            >
              {!loading && view === "progress" && (
                <CheckIns
                  isCheckinsLoading={isCheckinsLoading}
                  docId={this.docId}
                  userDoc={userDoc}
                  measurableTags={this.props.measurableTags}
                  measurableUnits={this.props.measurableUnits}
                  unitSystem={this.props.unitSystem}
                  unitDetails={this.props.unitDetails}
                  checkins={checkins}
                  loadMore={this.requestCheckins}
                  checkinsAggregate={checkinsAggregate}
                  checkinAggPhotosArray={checkinAggPhotosArray}
                  measurementsAggregate={measurementsAggregate}
                />
              )}
              {!loading && view === "schedule" && <UserSchViewer saveSchAggrs={this.saveSchAggrs}/>}
              {!loading && view === "notes" && (
                <Notes userDoc={userDoc} id={this.docId} noteDocs={noteDocs} loadNotes={this.fetchNotes} />
              )}
              {!loading && view === 'chats' && (
                  <ChatView
                    id={this.docId}
                    {...userDoc}
                    appConfig={this.state.appConfig}
                    toggleFlag={this.toggleFlag}
                    persistChat
                    chatKey={`client/${this.props.userPage}/${this.docId}`}
                  />
                )}
              {!loading && view === "trends" && (
                <Progress userDoc={userDoc} planDoc={planDoc} />
              )}
              {!loading && view === "overview" && (
                <Overview
                  handleResendEmail={this.handleResendEmail}
                  userDoc={userDoc} 
                  planDoc={planDoc} 
                  sessionDocs={sessionDocs} 
                  loadSessions={this.fetchSessions}
                  loadNotes={this.fetchNotes}
                  noteDocs={noteDocs}
                  checkinsAggregate={checkinsAggregate}
                  loadCheckinsAggregate={this.loadCheckinsAggregate}
                  checkinAggPhotosArray={checkinAggPhotosArray}
                />
              )}
            </div>
            {isActivateConfirmationOpen && (
              <Confirmation
                open
                handleClose={() =>
                  this.setState({ isActivateConfirmationOpen: false })
                }
                handleChange={this.activatePlan}
                handleCancel={() =>
                  this.setState({ isActivateConfirmationOpen: false })
                }
                msg="Start date of the plan of this user has passed. Do you still want to continue?"
                confirmOption="Yes, Continue"
              />
            )}

            {!!editUser &&
              <CreateEditForm
                loading={!!this.props.loaderCount}
                doc={userDoc}
                handleChange={this.handleChange}
                onCancel={this.cancelUserForm}
                onSubmit={this.onSubmit}
                errors={this.state.errors}
                packDoc={packDoc}
                handleChangeWrapper={this.handleChangeWrapper}
                pricingDoc={pricingDoc}
                isPlanChange={isPlanChange}
              />
            }
            {!!this.state.showExtendPlanDialogConfirmation &&
            <Confirmation
              open={this.state.showExtendPlanDialogConfirmation}
              handleChange={() => this.setState({ showExtendPlanDialog: true })}
              handleCancel={() => this.setState({ showExtendPlanDialogConfirmation: false })}
              title={"Extend Plan"}
              msg={"Plan is expired, do you want to extend plan?"}
              confirmOption="Yes"
          />
            }
            {!!this.state.showExtendPlanDialog &&
              <ChangePlanDates
                handleClose={() => this.setState({ showExtendPlanDialog: false, showExtendPlanDialogConfirmation: false })}
                userDoc={userDoc}
                planDoc={planDoc}
                userProfileId={this.docId}
                extend />
            }
            {!!this.state.openChangeClientEmail && <ChangeEmailDialog
              updateUMS={this.props.updateUMS} userDoc={this.props.userDoc}
              onClose={this.toggleChangeClientEmail}/>}
          </div>
        </UserSchProvider>
        </ProgressTrendsProvider>
      </UserContext.Provider>
    );
  }
}
const mapStateToProps = (s, op) => {
  return {
    questions:
      (s.questionnaires.docs && s.questionnaires.docs.questions) || undefined,
    companyData: s.app,
    unitSystem:
      (s.staff && s.staff.doc && s.staff.doc.unit_system) ||
      DEFAULT_UNIT_SYSTEM,
    unitDetails:
      (s.staff && s.staff.doc && s.staff.doc.unit_details) ||
      {},
    measurableTags: (s.tags && s.tags.docs && s.tags.docs[0]) || {},
    measurableUnits: (s.config && s.config.docs && s.config.docs[0]) || {},
    ...s.app,
    browseUsersList: s.browseUsersList,
    chatUsersList: s.chatList,
    checkinsUsersList: s.checkinsList,
    ptuUsersList: s.planstoupdateList,
    ptaUsersList: s.planstoassignList,
    csUsersList: s.completingsoonList,
    userDoc: _.get(s, "selectedSchedule.user"),
    planDoc:_.get(s, "selectedSchedule.plan"),
    newWeeks:_.get(s, "selectedSchedule.schedule")
  };
};
const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);

  const {
    insert: insertbrowseUsersList,
    update: updatebrowseUsersList,
    delete: deletebrowseUsersList
  } = browseusrRdxFns(d);
  const {
    setPlan, setUser, updateUMS, setSchedule, resetSchedule
  } = selectedScheduleRdxFns(d);
  const { update: updatecheckinsUsersList } = chekinusrRdxFns(d);
  const { update: updatechatUsersList } = chatusrRdxFns(d);
  const { update: updateptaUsersList } = ptausrRdxFns(d);
  const {
    update: updateptuUsersList,
    delete: deleteptuUsersList,
    insert: insertptuUsersList
  } = ptuusrRdxFns(d);
  const { update: updatecsUsersList } = csusrRdxFns(d);
  const { insert: insertTags } = tagsRdxFns(d);

  return {
    showLoader,
    hideLoader,
    updatebrowseUsersList,
    updatecheckinsUsersList,
    updatechatUsersList,
    updateptaUsersList,
    updateptuUsersList,
    updatecsUsersList,
    deleteptuUsersList,
    deletebrowseUsersList,
    insertbrowseUsersList,
    insertptuUsersList,
    insertTags,
    setPlan,
    setUser,
    updateUMS,
    setSchedule,
    resetSchedule,
  };
};
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(UserDetails)
);
