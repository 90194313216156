import React,{createContext} from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import update from "immutability-helper";
import * as Sentry from '@sentry/browser';
import {DEFAULT_ERROR, FILE_STATUS, HUBSPOT_PROPS,PUBLISHED,DRAFT,DRAFT_STATES,DEFAULT_VID_UPLOAD_ERROR, VIMEO_VIDEO_URL_REGEX, ERROR_YOUTUBE_URL, YOUTUBE_VIDEO_URL_REGEX, ERROR_VIMEO_URL } from "fitbud/utils/constants";
import foodRecipesRepo from "fitbud/repo/foodRecipes";
import frsRdxFns from "fitbud/redux/foodRecipesSupps";
import foodRdxFns from "fitbud/redux/food";
import recipeRdxFns from "fitbud/redux/recipes";
import fileUploadRdxFns from "fitbud/redux/fileUpload";
// import FoodRecipesForm from "fitbud/views/foodRecipes/foodRecipesForm";
// import Details from "./details";
import RecipeDetails from "./newUi/recipeDetails";
import { uploadFile, getTags } from "fitbud/utils/services";
import appRdxFns from "fitbud/redux/app";
import _ from "lodash";
import {
  countDecimals, refreshDoc,isOnline,DEFAULT_SERVING_ID, GMML_SERVINGS, roundNumber
} from "fitbud/utils/helpers";
import {calculatePublishStatus,checkErrInMediaUpload} from "fitbud/utils/catalog";
import {
  ERROR_TITLE,
  ERROR_REF_NAME,
  ERROR_URL,
  FOODRECIPES_STORAGE_FILE_PATH,
  OFFLINE_ERROR,
  FETCH_ERROR
} from "fitbud/utils/constants";
import { isValidURL } from "fitbud/utils/helpers";
import CircularLoader from "fitbud/components/CircularLoader";
import PageNotFound from "fitbud/views/pageNotFound";
import {FirebaseAuthContext} from "fitbud/providers/firebase-auth";
import { bffUpdateHubspotProp,createVidObject } from "fitbud/api";
import FoodDetails from "fitbud/views/foodRecipes/newUi/foodDetails";
import firebase from "fitbud/firebase";
import {uploadPdf} from "fitbud/utils/aws";
import FoodForm from "./foodForm";
import RecipesForm from "./recipesForm";
import { exportTags } from "../workouts/helperfn";
import {getPreferredUnit} from "fitbud/views/foodRecipes/newUi/inputDropdown";
export const RecipeContext = createContext({});

class FoodRecipesInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      loadingTags:false,
      isValidId: true,
      doc: props.doc,
      oldDoc: props.doc,
      draftDoc: {},
      publishedDoc: {},
      errors: {},
      editMode: props.foodServingFormOnly||false,
      mediaFiles: (props.doc && props.doc.media) || [],
      servingData: [],
      uploadingError: false,
      orignalManualDoc: props.doc,
      isDocument: true,
      showingDoc: PUBLISHED,
      viewOrigDoc: false,
      fetchedIngredients:{}
    };
  }
  static contextType = FirebaseAuthContext;
  get authUser (){
    return this.context.authUser;
  }
  get isRecipe(){
    return _.get(this.state.doc,'type')==="recipe";
  }

  get docId() {
    return this.props.id;
  }

  get isNew() {
    return this.props.id === "new";
  }
  get draftFeature(){
    const {comp} = this.context;
    if(_.isUndefined(this.props.draftFeature)){
      return !!comp.data().features.draft;
    }
    return this.props.draftFeature;
  }
  get isDraftAvailable() {
    return !!_.get(
      this.state,
      "publishedDoc.publish_status",
      PUBLISHED
    ).includes("draft");
  }
  get type(){
    return this.props.type;
  }

  handleChange = (
    e,
    lock,
    { skipDirty = false, caloriesLock = false } = {}
  ) => {
    let newState;
    const key = e.target.id || e.target.name;
    let value = e.target.value;
    if (key === "serving" && value === "select") return;
    if (key === "description") value = value.trim();
    if(key === "serving_size"||key==="serves")  value = Number(value)
    if(key==="systags"){
      value=(value||[]).map(val => val.value);
    }
    const {orignalManualDoc} = this.state;
    const servingOldValue = orignalManualDoc.serving_size || 0;
    if(key === "duration"){
      value = Math.max(0, parseInt(e.target.value || 0)) * 60
    }

    if(e.target.type ==="checkbox"){
      value = !e.target.checked //two checkbox automaticCalories and automaticMacros are working in reverse order
    }

    newState = update(this.state, {
      doc: {
        [key]: {
          $set: value
        }
      }
    });

    if(!skipDirty && newState.doc.source === "fitbudd"){
      newState = update(newState, {
        doc: {
          source: {
            $set: "fitbudd_custom"
          }
        }
      });  
    }

    // const { macros } = orignalManualDoc;
    // const protein = isNaN(macros.protein) ? 0 : macros.protein;
    // const carbs = isNaN(macros.carbs) ? 0 : macros.carbs;
    // const fat = isNaN(macros.fat) ? 0 : macros.fat;
    // const multiplier = value && servingOldValue ? value/servingOldValue : 0;
    // if (lock && key === "serving_size" && value !== this.state.doc.serving_size) {
    //   newState = update(newState, {
    //     doc: {
    //       macros: {
    //         protein: {
    //           $set: Number((protein * multiplier).toFixed(2))
    //         },
    //         fat: {
    //           $set: Number((fat * multiplier).toFixed(2))
    //         },
    //         carbs: {
    //           $set: Number((carbs * multiplier).toFixed(2))
    //         }
    //       }
    //     }
    //   });
    // }

    // if(key === "serving_size" && caloriesLock){
    //   const updateCalories =
    //     (newState.doc.macros.protein + newState.doc.macros.carbs) * 4 + newState.doc.macros.fat * 9;
    //   newState = update(newState, {
    //     doc: {
    //       macros: {
    //         calories: {
    //           $set: updateCalories,
    //         },
    //       },
    //     },
    //   });
    // }


    if(key === "noAutomaticCalories" && !value){ //if automatic calculation true then calculate calories
      const updateCalories =
        (newState.doc.macros.protein + newState.doc.macros.carbs) * 4 + newState.doc.macros.fat * 9;
      newState = update(newState, {
        doc: {
          macros: {
            calories: {
              $set: updateCalories,
            },
          },
        },
      });      
    }

    this.setState({ ...newState, errors: {} });
  };

  handleCaloriesChange = (e)=>{
    const type = [e.target.id || e.target.name];
    let value = Number(e.target.value);;
    if(countDecimals(value)>0) return ;
    const newState = update(this.state,{
      doc:{
        macros:{
          [type]:{
            $set: value
          }
        }
      },
      orignalManualDoc:{ //ex
        macros:{
          [type]:{
            $set: value
          }
        }
      },
    })
    this.setState({ ...newState});
  }

  handleEditMode = () => {
    this.setState({ editMode: true });
    if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
      this.toggleDoc(DRAFT);
    }
  };

  handlePreferredUnit = (v,serving_type) => {
    const unit = serving_type === "meal_volume" ? "ml" : "g";  
    const newState = update(this.state, {
      doc: {
        preferred_unit:{
          $set:v
        },
        serving_unit: {
          $set: unit
        },
        serving_type: {
          $set: serving_type
        }
      }
    });
    this.setState({ ...newState, errors: {} });
  };

  handleCancel = () => {
    const newState = update(this.state, {
      doc: {
        $set: { ...this.state.oldDoc }
      },
      orignalManualDoc: {
        $set: { ...this.state.oldDoc }
      },
      editMode: {
        $set: false
      },
      errors: {
        $set: {}
      },
    });
    this.setState({ ...newState });
    if(this.props.foodServingFormOnly){
      this.props.handleFoodOnlyCb();
    }
  };

  valid = (updatedDoc) => {
    let out = true;
    let errors = {};
    const {
      ref_name="",
      title="",
      macros,
      serving_size,
      link,
      serving,
      document,
      media,
      serving_options=[]
    } = updatedDoc;
    const { carbs, fat, protein,calories } = macros;
    // const macrosTotal = carbs + fat + protein;
    let type = updatedDoc.type;
    let _outState = this.state;
    if (this.isNew) {
      type = this.props.type;
    }

    if (!ref_name.trim()) {
      errors.ref_name = ERROR_REF_NAME;
      out = false;
    }

    if (!title.trim()) {
      errors.title = ERROR_TITLE("display name");
      out = false;
    }

    if (!serving) {
      errors.serving = "Please provide a serving item";
      out = false;
    }
    //----Supplement module is separated
    // if (type === "supplement" && !!link && !isValidURL(link)) {
    //   errors.link = ERROR_URL;
    //   out = false;
    // }

    if (media && media[0] && !!media[0].url) {
      if(media[0].type === "youtube") {
        if(!YOUTUBE_VIDEO_URL_REGEX.test(media[0].url)) {
          errors.mediaUrl = ERROR_YOUTUBE_URL;
          out = false;
        }
      } else if(media[0].type === "vimeo") {
        if(!VIMEO_VIDEO_URL_REGEX.test(media[0].url)) {
          errors.mediaUrl = ERROR_VIMEO_URL;
          out = false;
        }
      } 
    }

    if(type === "recipe"){
      const _document = document && document[0] ||  {}
      const {type, url, name:documentName} = _document;
      const documentErrors={};
      if(type === "link" && !!url){
        if(!isValidURL(url)){
          documentErrors.url = ERROR_URL;
          out = false;
        }
      }
      errors.document = {...documentErrors};
    }
    if (type === "food" || type==="fdc"){
      if(!!serving_options.length){
        const servingSizeArrs=[...serving_options.map(i=>i.serving_size),serving_size];
        const isServingSizeTypeSame=!_.sum(servingSizeArrs)?true:!servingSizeArrs.includes(0);
        if(!isServingSizeTypeSame){
          out=false;
          this.props.enqueueSnackbar( "The serving size cannot be 0. Please enter a non-zero value.",{ variant: "error" });
          errors['serving_size']=true;
        }
      }
      // if(macrosTotal > (serving_size || 0)){
      //   this.props.enqueueSnackbar(
      //     "The sum total of Protein, Fat & Carbs cannot exceed the serving size.",
      //     { variant: "error" }
      //   );
      //   out = false;
        //TODO: this will not reflect in form, as form via details gets oldDoc not doc(which was intended) but FIX-BELOW-CODE
        // _outState = update(_outState, {
        //   doc:{
        //     noAutomaticMacros :{
        //       $set:true,
        //     } 
        //   }
        // });
      // }
      // if((!!carbs || !!fat || !!protein || !!calories) && !serving_size){
      //   this.props.enqueueSnackbar(
      //     "Please enter serving size for given macros.",//waiting update from Kanika
      //     { variant: "error" }
      //   );
      //   errors['serving_size']=true;
      //   out = false;
      // }
    }
    _outState = update(_outState,{
      errors:{
        $set:errors
      }

    });
    this.setState(_outState);
    return out;
  };

  deleteItem = () => {
    const {
      onDelete,
      enqueueSnackbar,
      hideLoader,
      showLoader,
    } = this.props;
    showLoader();
    foodRecipesRepo(this.context.cid,this.draftFeature)
      .delete(this.docId)
      .then((doc) => {
        hideLoader();
        if (doc) {
          this.props.deleteFoodRecipes(doc.id);
          this.props.delete(doc.id);
          if (!!onDelete) onDelete();
          enqueueSnackbar("Item deleted successfully.", { variant: "success" });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  }

  copyItem = () =>{
    const {
      hideLoader,
      showLoader,
      insert,
      onSave,
      enqueueSnackbar,
    } = this.props;
    const { doc } = this.state;
    showLoader();
    let copyDoc = update(doc, {
      ref_name: {
        $set: `Copy of ${doc.ref_name}`,
      },
    });
    foodRecipesRepo(this.context.cid,this.draftFeature)
      .create(copyDoc)
      .then((doc) => {
        hideLoader();
        if (doc) {
          const _doc=doc.data();
          const newDoc = {
            _id: doc.id,
            data: {
              cid: this.context.cid,
              ref_name: _doc.ref_name,
              type: _doc.type,
              dtype: _doc.type,
              source: _doc.source,
              thumbnail : _doc.thumbnail,
              macros : _doc.macros,
              serving :_doc.serving,
              serving_size: _doc.serving_size,
              serving_unit: _doc.serving_unit,
              serving_type: _doc.serving_type,
              publish_status:_doc.publish_status
            },
          };
          insert(newDoc);
          this.props.insertFoodRecipes(newDoc);
          if (!!onSave) onSave(doc);
          enqueueSnackbar(`${this.type === "recipe" ? "Recipe":"Food"} has been copied`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  }

  
  //DOCS :: uploading documents
  uploadDocumentToS3 = async ({ id, docName, file, s3Path, rest,isPublish }) => {
    const { uploadDoc } = this.props;
    uploadDoc({
      docId: id,
      docName,
      collection: `companies/${this.context.cid}/${
        isPublish ? "foodRecipes" : "draft-foodRecipes"
      }`,
      cid: this.context.cid,
      file,
      path: `/nutrition/recipes/${id}`,
      s3Path,
      rest, //contain extra info which needs to store with document collection
    });
  };
  handlePublish =async (params = {}) => {
    //to test if params is 'e' , using param.target
    const isE=!!params.target;
    if(this.isDraftAvailable && this.state.showingDoc!==DRAFT){
      await this.toggleDoc(DRAFT);
    }
    this.onSubmit(PUBLISHED, isE ? {} : params);
  };
  handleDraft = (params = {}) => {
    //to test if params is 'e' , using param.target
    const isE=!!params.target;
    this.onSubmit(DRAFT, isE ? {} : params);
  };

  uploadVideoToBunnyCDN=async({id,file,isPublish,docName,...videoObject})=>{
    const { uploadBunnyFile } = this.props;
    uploadBunnyFile({
      docId:id,
      cid:this.context.cid,
      file:file.url,
      collection:isPublish ? "foodRecipes" : "draft-foodRecipes",
      media_bgoff:false,
      path:`/nutrition/recipes/${id}`,
      docName,
      videoObject,
    });
  }
  createVideoObject=async({id,isPublish,duration,title,resolution})=>{
    try{
      const collection=isPublish ? "foodRecipes" : "draft-foodRecipes";
      const resp=await createVidObject({
        cid:this.context.cid, 
        docId:id,
        path: `companies/${this.context.cid}/${collection}/${id}`,
        collection,
        media_bgoff:false,
        video:{
          title,
          duration,
          resolution
        }
      });
      return await resp.data;
    }
    catch(err){
      this.props.enqueueSnackbar(_.get(err,"response.data.message",DEFAULT_VID_UPLOAD_ERROR),{variant:"error"});
      console.log("err",err);
      return null;
    }
  }

  // onSaveIngredient=async(docType = PUBLISHED,params={})=>{
  //   const {ref_id,...data}=params;
  //     if(!ref_id){
  //       // create
  //       return foodRecipesRepo(this.context.cid,this.draftFeature)
  //       .create(data);
  //     }
  //     else{
  //       return foodRecipesRepo(this.context.cid,this.draftFeature)
  //       .update(ref_id,data,null,docType)
  //     }
  // }
  sanitizeCode=(updatedDoc={})=>{
    const { servingData } = this.state;
    if (!this.isRecipe) {//food
      const { serving_options = [] } = updatedDoc;
      if (serving_options.length) {
        const updatedServingOptions = [];
        serving_options.forEach((i) => {
          if (i.id) {
            if(!!this.props.foodServingFormOnly && !!i.serving_size && !updatedDoc.serving_size){
              updatedDoc.serving_size=i.serving_size;
            }
            const serving = servingData.find((j) => j[0] === i.id);
            updatedServingOptions.push({
              ...i,
              label: _.get(serving, `${1}.value`, ""),
            });
          }
        });
        updatedDoc["serving_options"] = [...updatedServingOptions];
      }
      if(this.props.foodServingFormOnly){
        //update state
        const _outState=update(this.state,{
          doc:{
            serving_size:{
              $set:updatedDoc.serving_size
            }
          }
        });
        this.setState(_outState);
      }
    }
    return updatedDoc;
  }
  handleServingChange=({serves,adjustIngredients=true})=>{
    let updatedDoc=update(this.state.doc,{
      serves:{
        $set:serves,
      },
    });
    if(adjustIngredients){
      const ratio=serves/(this.state.doc.serves||1);
      let ingredients=this.state.doc.ingredients,ingredientsv2=this.state.doc.ingredientsv2;
      if(ingredients){
        ingredients=ingredients.map(i=>({
          ...i,
          serving_size:_.isNumber(i.serving_size)?roundNumber(ratio*i.serving_size):i.serving_size
        }));
        updatedDoc=update(updatedDoc,{
          ingredients:{
            $set:ingredients
          },
        });
      }
      if(ingredientsv2){
        ingredientsv2=ingredientsv2.map(i=>({
          ...i,
          items:i.items.map(j=>({
            ...j,
            serving_size:_.isNumber(j.serving_size)?roundNumber(j.serving_size*ratio):j.serving_size
          }))
        }));
        updatedDoc=update(updatedDoc,{
          ingredientsv2:{
            $set:ingredientsv2
          },
        });
      }
    }
    this.props.showLoader();
    foodRecipesRepo(this.context.cid,this.draftFeature)
    .update(this.docId,updatedDoc,null)
    .then(doc=>{
      this.props.hideLoader();
      this.setState({
        doc:doc.data(),
        oldDoc: doc.data(),
      })
    }).catch(err=>{
      this.props.hideLoader();
      this.props.enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    });
  }
  onSubmit = async (docType = PUBLISHED, params = {}) => {
    const {
      hideLoader,
      showLoader,
      insert,
      onSave,
      enqueueSnackbar,
      handleClose
    } = this.props;
    const { doc, isDocument } = this.state;
    let { thumbnail, media = {}, document } = doc;
    let updatedDoc = Object.assign({}, doc, params);
    updatedDoc=this.sanitizeCode(updatedDoc);
    if (!this.valid(updatedDoc)) return;
    const calculatedDocType=calculatePublishStatus(this.isNew,this.draftFeature,this.state.oldDoc,docType);
    const isPublish=calculatedDocType===DRAFT_STATES['PUBLISHED']
    showLoader();
    // Close Fdc selector dialog
    handleClose && handleClose();
    if (thumbnail && thumbnail.file) {
      const imageUrl = await uploadFile({
        file: thumbnail.file,
        filePath: FOODRECIPES_STORAGE_FILE_PATH
      });
      updatedDoc = update(updatedDoc, {
        thumbnail: {
          $set: imageUrl.replace("original", "240")
        }
      });
    }
    let newMediaAvailable=!!_.get(media,"0.url.name", null);
    if(newMediaAvailable){
      const firebaseId=this.isNew?firebase
      .firestore()
      .collection(`companies/${this.context.cid}/foodRecipes`)
      .doc().id:this.docId;
      //create video obj
      const resp=await this.createVideoObject({
        id:firebaseId,
        isPublish,
        duration:_.get(media,"0.duration"),
        title:_.get(media,"0.url.name",""),
        resolution:_.get(media,"0.height","")
      });
      if(!resp){
        hideLoader();
        return;
      }
      newMediaAvailable=resp;
      updatedDoc=update(updatedDoc,{
        media:{
          $set:[{
            type:"video",
            status:resp?FILE_STATUS.uploading:FILE_STATUS.error, 
            videoId:_.get(resp,'videoId',"")
          }]
        },
      });
      if(this.isNew){
        updatedDoc=update(updatedDoc,{
          docIdx:{
            $set:firebaseId
          }
        });
      }
    }
    else{
      if(!!media[0] && ["youtube", "vimeo"].includes(media[0].type) && media[0].url){
        updatedDoc = update(updatedDoc, {
          media: {
            $set: [{ type: media[0].type, url: media[0].url, ...(media[0].error && {error: media[0].error}) }]
          }
        });
      }
    }
    const _document = document && document[0] || {};
    const _document_type = _.get(_document, "type", "");
    const _isLocalFile = _.get(_document, "isLocal", false);
    if(_document_type === "file" && _isLocalFile &&  _document.url){ //ie raw pdf save to doc state for showing progress
      try {
        const _docResponse = await uploadPdf({ cid: this.context.cid, file: _document.url });
        updatedDoc = update(updatedDoc, {
          document: {
            $set: [_.assign(_docResponse,{name:_.get(_document,"name")})], //assign extra filed here like name
          },
        });
      } catch (err) { //If document upload failed then show error and return::
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
        return;
      }
    }
    const _rdxState = {
      cid: this.context.cid,
      ref_name: updatedDoc.ref_name,
      dtype: updatedDoc.type,
      source: updatedDoc.source,
      thumbnail:(calculatedDocType===DRAFT_STATES['DRAFT_ONLY'] || calculatedDocType === DRAFT_STATES["PUBLISHED"])
          ? updatedDoc.thumbnail
          : _.get(this.state.publishedDoc, "thumbnail"),
      macros: _.get(updatedDoc, "macros", {}),
      serving: _.get(updatedDoc, "serving"),
      serving_size: _.get(updatedDoc, "serving_size"),
      serving_unit: _.get(updatedDoc, "serving_unit"),
      serving_type: _.get(updatedDoc, "serving_type"),
      publish_status: calculatedDocType,
      primary_serving:_.get(updatedDoc,'serving_options.0',{})
    };

    if (this.isNew) {
      const { type } = this.props;
      updatedDoc = update(updatedDoc, {
        type: {
          $set: type
        }
      });
      if (type === "food" && !doc.serving_unit) {
        updatedDoc = update(updatedDoc, {
          serving_unit: {
            $set: "g"
          },
          serving_type: {
            $set: "meal_weight"
          }
        });
        _rdxState.serving_unit = "g";
        _rdxState.serving_type = "meal_weight";
      }

      const createNewId=newMediaAvailable?false:true;
      foodRecipesRepo(this.context.cid,this.draftFeature)
        .create(updatedDoc,null,createNewId)
        .then(doc => {
          delete updatedDoc.docIdx;
          let hubspotProp = '';
          if (type === 'food') hubspotProp = HUBSPOT_PROPS.FOOD_CREATED;
          if (type === 'recipe') hubspotProp = HUBSPOT_PROPS.RECIPE_CREATED;
          if (type === 'fdc') hubspotProp = HUBSPOT_PROPS.FOOD_DB_USED;
          if (hubspotProp) bffUpdateHubspotProp(hubspotProp);
          hideLoader();
          if (doc) {
            if (newMediaAvailable) {
              //upload video in bunnycdn
              this.uploadVideoToBunnyCDN({
                id: doc.id,
                file: media[0],
                isPublish,
                docName: doc.data().ref_name,
                ...newMediaAvailable,
              });
            }
            const newDoc = {
              _id: doc.id,
              data: _rdxState
            };
            insert(newDoc);
            this.props.insertFoodRecipes(newDoc);
            if (!!onSave) onSave(doc);
          }
        })
        .catch(err => {
          hideLoader();
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
        });
    } else {
      //----hack-----
      //avoids BE flag pipeline, directly puts media in main collection, check flow in companyBase
      if (
        isPublish &&
        newMediaAvailable &&
        (updatedDoc.publish_status || "").includes("draft")
      ) {
        updatedDoc["to_publish"] = true;
      }
      //-------------
      let _out = updatedDoc;

      foodRecipesRepo(this.context.cid,this.draftFeature)
        .update(this.docId, _out, null, docType)
        .then(doc => {
          hideLoader();
          if (doc) {
            if (newMediaAvailable) {
              this.uploadVideoToBunnyCDN({
                id:doc.id,
                file: media[0],
                isPublish,
                docName: updatedDoc.ref_name,
                ...newMediaAvailable,
              });
            }
            const latestDoc = {
              _id: doc.id,
              data: _rdxState
            };
            this.props.update(latestDoc);
            this.props.updateFoodRecipes(latestDoc);
            const to_publish = doc.data().to_publish;
            //---TODO ⬇︎ :replace docType with calculatedDocType ?? 
            this.setState((o) => ({
              editMode: false,
              showingDoc: to_publish ? DRAFT : docType,
              oldDoc: doc.data(),
              doc: doc.data(),
              publishedDoc:(docType===PUBLISHED && !to_publish)?doc.data():{...(o.publishedDoc||{}),publish_status:doc.data().publish_status},
              draftDoc:docType===DRAFT?doc.data():{...(o.draftDoc||{}),to_publish},
              orignalManualDoc: doc.data(),
            }));
            //-------
          }
          if(this.props.foodServingFormOnly){
            this.props.handleFoodOnlyCb(doc.data());
          }
        })
        .catch(err => {
          hideLoader();
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    }
  };
  videoStatusRefresh = () => {
    const { files } = this.props;
    const {showingDoc,draftDoc,publishedDoc} = this.state;
    const ongoingPipeline=draftDoc && !!draftDoc.to_publish && publishedDoc && publishedDoc.publish_status.includes('draft');
    let _currentshowingDoc=ongoingPipeline?PUBLISHED:showingDoc;
    const media=_.get(
      _currentshowingDoc === PUBLISHED ? { ...publishedDoc } : { ...draftDoc },
      'media.0'
    );
    const file = files.find(i=>i.id===media.videoId)||{};
    if (file.status === FILE_STATUS.processing ||media.status===FILE_STATUS.processing) {
      const fetch = () => !this.isNew && this.fetch(_currentshowingDoc === DRAFT);
      refreshDoc(fetch, this.props, (data) => {
        const latestPublishedStatus=_.get(data,'doc.publish_status');
        if(ongoingPipeline){
          if(latestPublishedStatus===DRAFT_STATES['PUBLISHED']){
          //pipeline completed
          this.setState({
            publishedDoc:{...data.doc},
            doc: { ...data.doc },
            oldDoc:{...data.doc},
            showingDoc:PUBLISHED
          })
          }
          else{
            //ignore
          }
        }
        else{
          this.setState({
            doc: { ...data.doc },
            oldDoc: { ...data.doc },
            orignalManualDoc: { ...data.doc },
          });
        }
      });
    }
  }
  updateMediaMeta=(args={})=>{
    const {media}=this.state.doc;
    if(media && !!_.isMatch(media[0],args)) return;
    const newState=update(this.state,{
      doc:{
        media:{
          0:{
            $merge:{
              ...args
            }
          }
        }
      }
    });
    this.setState({
      ...newState
    });
  }

  setMedia = (media) => {
    const changes = { media: { $set: media } };
    if (media.length && media[0].type === 'image' && media[0].url)
      changes.thumbnail = { $set: media[0].url };
    this.setState(update(this.state, { doc: changes }));
  }

  handleMedia = async (media) => {
    let {type, url} = media;
    if(!!_.get(media, "vimeo", ""))type = "vimeo";  //vimeo video hack ....
    let newState = this.state;
    if (['youtube', 'vimeo'].includes(type)) {
      newState = update(newState, {
        doc: {
          media: {
            $set: [media],
          },
        },
      });
    } else if (type === "video") {
      newState = update(newState, {
        doc: {
          media: {
            $set: [{ type: "video", url }],
          },
        },
      });
    } else if (type === "image") {
      const fallback = this.state.doc.thumbnail || '';
      if (!url)
        newState = update(newState, { doc: { media: { $set: [{ type: 'image', url: fallback }] } } });
      else if (!this.state.doc.media.length || !this.state.doc.media[0].url)
        newState = update(newState, { doc: { media: { $set: [{ type: 'image', url }] } } });
      else
        newState = update(newState, { doc: { media: { $push: [{ type: 'image', url }] } } });
    } else {
      newState = update(newState, {
        doc: {
          thumbnail: {
            $set: {
              file: url,
              url: URL.createObjectURL(url),
            },
          },
        },
      });
    }

    this.setState(newState);
  };

  handleDocument = (type, file, meta = false) =>{
    const { doc } = this.state;
    let newDoc = doc;
    const  _docName = _.get(doc, "document.0.name", "");
    if (type === 'file') { //incase of file
      let _document = [{ type, name:_docName }];
      if (!!file) { 
          if(meta) _document = [{ type, ...(file || {}) }]; //if meta is true ie only meta fields changes like name
          else  _document = [{ url: file, file_original_name: file.name, size: file.size, type,  isLocal:true, name:_docName }];
      }
      newDoc = update(doc, {
        document: {
          $set: _document,
        },
      });
    } else if (type === 'link') { //incase iof link
      let _document = [{ type,name:_docName, ...(file || {}) }];
      newDoc = update(doc, {
        document: {
          $set: _document,
        },
      });
    }
    this.setState({doc:newDoc, dirty:true});
  }

  handleIsDocument = (e) =>{
    let value = e.target.checked;
    this.setState({isDocument:value})
  }

  removeMedia = type => {
    let newState = this.state;
    if (['youtube', 'vimeo'].includes(type)) {
      newState = update(newState, {
        doc: {
          media: {
            $set: [
              {type, url:""}
            ]
          }
        },
        dirty: {
          $set: true
        }
      });
    }else if (type === "video") {
      newState = update(newState, {
        doc: {
          media: {
            $set: [
              {type:"video", url:""}
            ]
          }
        },
        dirty: {
          $set: true
        }
      });
    } else {
      newState = update(newState, {
        doc: {
          thumbnail: {
            $set: ""
          }
        },
        dirty: {
          $set: true
        }
      });
    }

    this.setState(newState);
  };

  // updateParent = params => {
  //   this.setState(params);
  // };

  handleMacros =( e, isCaloriesLock = true )=> {
    const type = [e.target.id || e.target.name];

    if (e.target.type === "number") {
      //validate Decimal Number
      if(countDecimals(e.target.value) > 2){
        return;
      }
      e.target.value = Number(e.target.value);
    }

    let newState = update(this.state, {
      doc: {
        macros: {
          [type]: {
            $set: Number(e.target.value)
          }
        }
      }
    });

    if(newState.doc.source === "fitbudd"){
      newState = update(newState, {
        doc: {
          source: {
            $set: "fitbudd_custom"
          }
        }
      });  
    }

    
    
    const { macros } = newState.doc;
    const protein = isNaN(macros.protein) ? 0 : macros.protein;
    const carbs = isNaN(macros.carbs) ? 0 : macros.carbs;
    const fat = isNaN(macros.fat) ? 0 : macros.fat;
    if(isCaloriesLock){ //if calories is lock then calculate according to macros is unlock then leave it is.
      const calories = (protein + carbs) * 4 + fat * 9;
      newState = update(newState, {
        doc: {
          macros: {
            calories: {
              $set: calories
            }
          }
        }
      });
    }
    

    this.setState({ ...newState, errors: {} });
  };

  handlePrevMacros = (lock) => {
    const { doc={} } = this.state;
    if(!!lock){
      const { macros } = doc;
      this.setState({
        oldMacros: {...macros}
      })
    }else{
      let updatedDoc = {...doc};
      updatedDoc.macros = {...(doc.macros||{})}
      this.setState({
        doc: {...updatedDoc},
        orignalManualDoc: {...doc}
      })
    }
  };

  toggleDoc = (docType) => {
    const { draftDoc, publishedDoc } = this.state;
    const doc = this.parseCurrentDoc(
      docType === DRAFT ? { ...draftDoc } : { ...publishedDoc }
    );
    if (!Object.keys(doc).length) return;
    this.setState({ showingDoc: docType, doc: { ...doc }, oldDoc: { ...doc } });
  };

  discardDraft = () => {
    const { showLoader, hideLoader, enqueueSnackbar, onDelete } = this.props;
    showLoader();
    foodRecipesRepo(this.context.cid,this.draftFeature)
      .deleteDraft(this.docId, null, this.state.doc.publish_status)
      .then((doc) => {
        hideLoader();
        if (!doc) return;
        const updatedDoc = doc.data();
        if (
          _.get(updatedDoc, "publish_status", "") === DRAFT_STATES["DRAFT_ONLY"]
        ) {
          this.props.deleteFoodRecipes(doc.id);
          this.props.delete(doc.id);
          if (!!onDelete) onDelete();
        } else if (
          _.get(updatedDoc, "publish_status", "") === DRAFT_STATES["PUBLISHED"]
        ) {
          this.setState((o) => ({
            oldDoc: { ...updatedDoc },
            doc: { ...updatedDoc },
            publishedDoc: { ...o.publishedDoc, publish_status: PUBLISHED },
            showingDoc:PUBLISHED
          }));
          this.props.updateFoodRecipes({
            _id: doc.id,
            data: {
              cid: this.context.cid,
              ref_name: updatedDoc.ref_name,
              dtype: updatedDoc.type,
              source: updatedDoc.source,
              thumbnail: updatedDoc.thumbnail,
              macros: _.get(updatedDoc, "macros", {}),
              serving: _.get(updatedDoc, "serving"),
              serving_size: _.get(updatedDoc, "serving_size"),
              serving_unit: _.get(updatedDoc, "serving_unit"),
              serving_type: _.get(updatedDoc, "serving_type"),
              publish_status: updatedDoc.publish_status,
            },
          });
        }
        enqueueSnackbar("Draft deleted successfully.", { variant: "success" });
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  };
  stopPublishPipeline=async()=>{
    try{
      const draftDoc=await foodRecipesRepo(this.context.cid,this.draftFeature)
      .stopPublishPipeline(this.docId);
      if(!draftDoc || !draftDoc.exists) {
        this.props.enqueueSnackbar(DEFAULT_ERROR,{variant:"error"});
        return;
      }
      const parsedDraftDoc=this.parseCurrentDoc(draftDoc.data());
      this.setState(o=>({
        draftDoc:{...parsedDraftDoc},
        doc:o.showingDoc===DRAFT?{...parsedDraftDoc}:{...o.doc},
        oldDoc:o.showingDoc===DRAFT?{...parsedDraftDoc}:{...o.oldDoc},
      }));
      return draftDoc;
    }
    catch(err){
      this.props.enqueueSnackbar(DEFAULT_ERROR,{variant:"error"});
      console.log(err);
    }
  }
  updateFetchedIngredients=(change)=>{
    this.setState(o=>({
      fetchedIngredients:{...o.fetchedIngredients,...change}
    }));
  }
  
  render() {
    const {
      doc,
      loader,
      errors,
      editMode,
      servingData,
      isValidId,
      oldDoc,
      showingDoc,
      draftDoc,
      fetchedIngredients={},
      nutritionTags,
      loadingTags,
    } = this.state;
    const loading=this.props.loading||loadingTags||loader;
    const {  type, onSave,foodServingFormOnly=false } = this.props;
    const isFood=type === "food" || type === "fdc";
    if (!isValidId) return <PageNotFound keyName="food & recipe" />;
    if (this.isNew)
      return (
        <>
          {isFood ? (
            <FoodForm
              loading={loading}
              isNew
              type={type}
              doc={doc}
              errors={errors}
              onSave={onSave}
              handleChange={this.handleChange}
              onCancel={this.props.onCancel}
              onSubmit={this.onSubmit}
              handleMedia={this.handleMedia}
              handleDocument={this.handleDocument}
              handleIsDocument={this.handleIsDocument}
              isDocument={this.state.isDocument}
              removeMedia={this.removeMedia}
              handleMacros={this.handleMacros}
              handlePreferredUnit={this.handlePreferredUnit}
              servingData={servingData}
              handlePrevMacros={this.handlePrevMacros}
              handleCaloriesChange={this.handleCaloriesChange}
              updateMediaMeta={this.updateMediaMeta}
              nutritionTags={nutritionTags}
            />
          ) : (
            <RecipesForm
              cid={this.context.cid}
              loading={loading}
              isNew
              type={type}
              doc={doc}
              errors={errors}
              onSave={onSave}
              handleChange={this.handleChange}
              onCancel={this.props.onCancel}
              onSubmit={this.onSubmit}
              setMedia={this.setMedia}
              handleMedia={this.handleMedia}
              handleDocument={this.handleDocument}
              handleIsDocument={this.handleIsDocument}
              isDocument={this.state.isDocument}
              removeMedia={this.removeMedia}
              handleMacros={this.handleMacros}
              servingData={servingData}
              handlePrevMacros={this.handlePrevMacros}
              handleCaloriesChange={this.handleCaloriesChange}
              updateMediaMeta={this.updateMediaMeta}
              nutritionTags={nutritionTags}
            />
          )}
        </>
      );
    return (
      <RecipeContext.Provider 
        value={{
          fetchedIngredients,
          updateFetchedIngredients:this.updateFetchedIngredients,
          // onSaveIngredient:this.onSaveIngredient,
          fetch:this.fetchItem
          }}>
        <div className={`overflow-x-hidden ${doc ? "" : "h-100"} position-relative`}>
          {loading && <CircularLoader className="position-absolute" />}
          {doc && (
            <>
              {doc.type === "recipe" && !foodServingFormOnly  && (
                <RecipeDetails
                  id={this.props.id}
                  doc={{ ...oldDoc }}
                  cid={this.context.cid}
                  handleEditMode={this.handleEditMode}
                  servingData={servingData}
                  // updateParent={this.updateParent}
                  copyItem={this.copyItem}
                  deleteItem={this.deleteItem}
                  uploadingError={this.state.uploadingError}
                  videoStatusRefresh={this.videoStatusRefresh}
                  editMode={editMode}
                  isPreview={this.props.isPreview}
                  draftBannerProps={{
                    toggleDoc: this.toggleDoc,
                    isDraftAvailable: this.isDraftAvailable,
                    handlePublish: this.handlePublish,
                    discardDraft: this.discardDraft,
                    showingDoc: showingDoc,
                    videoStatusRefresh:this.videoStatusRefresh,
                    draftDoc
                  }}
                  draftFeature={this.draftFeature}
                  handleDraft={this.handleDraft}
                  handlePublish={this.handlePublish}
                  showingDoc={this.state.showingDoc}
                  toggleDoc={this.toggleDoc}
                  draftDoc={draftDoc}
                  isDraftAvailable={this.isDraftAvailable}
                  stopPublishPipeline={this.stopPublishPipeline}
                  nutritionTags={nutritionTags}
                  handleServingChange={this.handleServingChange}
                />
              )}
              {/* {!editMode && doc.type !== "recipe" && (
                <FoodSupplementDetails
                  id={this.props.id}
                  doc={doc}
                  handleEditMode={this.handleEditMode}
                  servingData={servingData}
                  copyItem={this.copyItem}
                  deleteItem={this.deleteItem}
                />
              )} */}
              {(doc.type === "food" || doc.type === "fdc") && !foodServingFormOnly && (
                <FoodDetails
                  id={this.props.id}
                  doc={{ ...oldDoc }}
                  handleEditMode={this.handleEditMode}
                  servingData={servingData}
                  copyItem={this.copyItem}
                  deleteItem={this.deleteItem}
                  isPreview={this.props.isPreview}
                  draftBannerProps={{
                    toggleDoc: this.toggleDoc,
                    isDraftAvailable: this.isDraftAvailable,
                    handlePublish: this.handlePublish,
                    discardDraft: this.discardDraft,
                    showingDoc: showingDoc,
                  }}
                  nutritionTags={nutritionTags}
                />
              )}
              {editMode && (
                <>
                {(isFood||foodServingFormOnly) ? (
                  <FoodForm
                  loading={loading}
                  doc={doc}
                  type={doc.type}
                  errors={errors}
                  handleChange={this.handleChange}
                  onCancel={this.handleCancel}
                  onSubmit={this.onSubmit}
                  handleMedia={this.handleMedia}
                  removeMedia={this.removeMedia}
                  handleMacros={this.handleMacros}
                  handlePreferredUnit={this.handlePreferredUnit}
                  handleCaloriesChange={this.handleCaloriesChange}
                  servingData={servingData}
                  handlePrevMacros={this.handlePrevMacros}
                  handleDocument={this.handleDocument}
                  isDocument={this.state.isDocument}
                  handleIsDocument={this.handleIsDocument}
                  isPreview={this.props.isPreview}
                  handleDraft={this.handleDraft}
                  handlePublish={this.handlePublish}
                  draftFeature={this.draftFeature}
                  videoStatusRefresh={this.videoStatusRefresh}
                  isDraftAvailable={this.isDraftAvailable}
                  updateMediaMeta={this.updateMediaMeta}
                  foodServingFormOnly={foodServingFormOnly}
                  nutritionTags={nutritionTags}
                />):
                (<RecipesForm
                  cid={this.context.cid}
                  loading={loading}
                  doc={doc}
                  type={doc.type}
                  errors={errors}
                  handleChange={this.handleChange}
                  onCancel={this.handleCancel}
                  onSubmit={this.onSubmit}
                  setMedia={this.setMedia}
                  handleMedia={this.handleMedia}
                  removeMedia={this.removeMedia}
                  handleMacros={this.handleMacros}
                  handleCaloriesChange={this.handleCaloriesChange}
                  servingData={servingData}
                  handlePrevMacros={this.handlePrevMacros}
                  handleDocument={this.handleDocument}
                  isDocument={this.state.isDocument}
                  handleIsDocument={this.handleIsDocument}
                  isPreview={this.props.isPreview}
                  handleDraft={this.handleDraft}
                  handlePublish={this.handlePublish}
                  draftFeature={this.draftFeature}
                  videoStatusRefresh={this.videoStatusRefresh}
                  isDraftAvailable={this.isDraftAvailable}
                  updateMediaMeta={this.updateMediaMeta}
                  nutritionTags={nutritionTags}
                />
                )}
                </>
              )}
            </>
          )}
        </div>
      </RecipeContext.Provider>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    //-----------------------media upload check------------------------
    const files=_.get(this.props, "files", []);
    const mediaObj= _.get(this.state, "oldDoc.media.0", {});
    const mediaError=checkErrInMediaUpload(mediaObj,files);
    let uploadingError=!!mediaError;
    if(!_.isEqual(this.state.uploadingError,uploadingError)){
      this.setState({ uploadingError: uploadingError });
    }
    //-------------------Update localDoc on file upload--------
    if(mediaObj.status===FILE_STATUS.uploading){
      const isFileInRedux=files.find(i=>i.id===mediaObj.videoId);
      if(isFileInRedux && isFileInRedux.status===FILE_STATUS.processing){
        const oldDoc=update(this.state.oldDoc,{
          media:{
            0:{
              status:{
                $set:FILE_STATUS.processing
              }
            }
          }
        });
        if(!this.draftFeature){
          //update publishedDoc only
          const publishedDoc=update(this.state.publishedDoc,{
            media:{
              0:{
                status:{
                  $set:FILE_STATUS.processing
                }
              }
            }
          });
          this.setState({publishedDoc});
        }
        else{
          //update draftDoc only
          const draftDoc=update(this.state.draftDoc,{
            media:{
              0:{
                status:{
                  $set:FILE_STATUS.processing
                }
              }
            }
          });
          this.setState({draftDoc});
        }
        this.setState({
          doc:{...oldDoc},
          oldDoc:{...oldDoc}
        });
      }
    }
  }
  parseCurrentDoc = (doc={}) => {
    if (!doc.source) {
      doc.source = !!doc.fdcId ? "fitbudd" : "custom";
    }
    if (!doc.serving) {
      doc.serving = DEFAULT_SERVING_ID
    }
    doc.preferred_unit=getPreferredUnit(doc);
    const {ingredientsv2=[]}=doc;
    const ingredientsId=[];
    ingredientsv2.forEach(sec=>{
      (sec.items||[]).forEach(({ref_id})=>{
        if(!!ref_id) ingredientsId.push(ref_id);
      })
    });
    this.fetchIngredients(_.uniq(ingredientsId));//no await required
    return { ...doc };
  };
  fetchIngredients= async (ids=[]) => {
    if(!!ids.length){
      const { fetchedIngredients = {} } = this.state;
      let existIds=[...Object.keys(fetchedIngredients)];
      const newIds = ids.filter((d) => existIds.indexOf(d) === -1) || [];
      if (!newIds.length) return;
      // if (!loader) this.props.showLoader();
      const dataSnapshot=await Promise.all(
        newIds.map(i=>foodRecipesRepo(this.context.cid).doc(i))
      );
      // if (!loader) this.props.hideLoader();
      const newFetchedIngs={};
      dataSnapshot.forEach(i=>{
        newFetchedIngs[i.id]={
          ...i.data(),
        }
      });
      this.setState(o=>({
        fetchedIngredients:{...o.fetchIngredients,...newFetchedIngs}
      }));
    }
  }
  getFoodRecDoc = async () => {
    try{
      // if (this.isNew && !this.props.fdcData) return;
      this.setState({ loader: true });
      let doc,
        oldDoc = {},
        publishedDoc = {},
        draftDoc = {},
        showingDoc;
      if (this.isNew) {
        if(this.props.fdcData){
          doc = {...this.props.fdcData,noAutomaticCalories:true};
          oldDoc = {...this.props.fdcData,noAutomaticCalories:true};
        }
        else{
          doc={...this.state.doc};
          oldDoc={...this.state.oldDoc};
        }
      } else if (!this.isNew) {
        //get published doc
        const foodRecDoc = await this.fetch();
        if (!foodRecDoc.exists) {
          this.setState({
            isValidId: false,
            loader: false,
          });
          return;
        }
        publishedDoc = foodRecDoc.data ? foodRecDoc.data() : foodRecDoc;
        //if draft feature is off, treat main collection doc as published
        if (!this.draftFeature) {
          publishedDoc["publish_status"] = DRAFT_STATES['PUBLISHED'];
        }
        const publish_status = _.get(publishedDoc, "publish_status", PUBLISHED);
        if (publish_status.includes("draft")) {
          //get draft doc
          const draftWo = await this.fetch(true);
          draftDoc = !!draftWo.data ? draftWo.data() : draftWo;
        }
        if (publish_status === DRAFT_STATES["DRAFT_ONLY"]) {
          doc = { ...draftDoc };
          showingDoc = DRAFT;
          oldDoc = { ...draftDoc };
        } else {
          doc = { ...publishedDoc };
          showingDoc = PUBLISHED;
          oldDoc = { ...publishedDoc };
        }
      }
      doc = { ...this.parseCurrentDoc(doc) };
      oldDoc = { ...this.parseCurrentDoc(oldDoc) };
      //hack: avoid race condition. Fix later
      setTimeout(()=>this.setState({
        doc,
        oldDoc,
        publishedDoc,
        draftDoc,
        loader: false,
        showingDoc,
        orignalManualDoc: doc,
      }),0);
  }
  catch(err){
    const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
    this.props.enqueueSnackbar(msg, {
      variant: "error",
    });
    this.setState({loader:false})
  }
  };

  componentDidMount() {
    this.setState({loadingTags:true });
    this.getFoodRecDoc();
    let supported = { food: "foods", recipe: "recipes" };
    const fetchNutritionTags = () => getTags("nutrition", supported[this.props.type], this.context.comp);
    const fetchServing = () => getTags("serving", undefined, this.context.comp);
    Promise.all([fetchServing(), fetchNutritionTags()])
      .then(([serving, nutritionTags]) => {
        let servingData = Object.entries(serving);
        this.setState({
          servingData,
          nutritionTags: exportTags(nutritionTags),
          loadingTags:false,
        });
      })
      .catch((err) => {
        const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
        this.props.enqueueSnackbar(msg, {
          variant: "error",
        });
        this.setState({loadingTags: false })
      });
  }

  fetch = (isDraft = false) => {
    return foodRecipesRepo(this.context.cid,this.draftFeature).doc(this.docId, null, isDraft);
  };
  fetchItem=(id,isDraft=false)=>{
    return foodRecipesRepo(this.context.cid,this.draftFeature).doc(id, null, isDraft);
  }
}

const mapStateToProps = (s, op) => {
  const id = op.id;
  if (id === "new") {
    const type = op.type;
    let _defaultDoc = {
      type,
      title: "",
      ref_name: "",
      source:"custom",
      thumbnail: "",
        macros: {
          protein: 0,
          fat: 0,
          carbs: 0,
          calories: 0
        }
    };
    if(type === "food"){
     _defaultDoc.serving_type = "meal_weight";
     _defaultDoc.serving_size = 100;
    } else if (type === 'recipe') {
      _defaultDoc.serves = 1;
    }

    return {
      doc: { ..._defaultDoc },
      loading: s.foodRecipes.loading,
      files : s.fileUpload.files
    };
  } else
    return { doc: undefined, loading: s.foodRecipes.loading,      files : s.fileUpload.files
    };
};

const mapDispatchToProps = (d, ownProps) => {
  const { showLoader, hideLoader } = appRdxFns(d);
  const rdxFns = ownProps.type === "recipe" ? recipeRdxFns : foodRdxFns;
  const { insert: insertFoodRecipes, update: updateFoodRecipes, delete: deleteFoodRecipes } = rdxFns(d);
  const { uploadFile, uploadDoc,uploadBunnyFile } = fileUploadRdxFns(d);

  return {
    ...frsRdxFns(d),
    insertFoodRecipes,
    updateFoodRecipes,
    deleteFoodRecipes,
    showLoader,
    hideLoader,
    uploadFile,
    uploadDoc,
    uploadBunnyFile
  };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(FoodRecipesInfo)
);
