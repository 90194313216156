import {
  bffSetDaySchedule,
  bffTrainerAvailability,
  bffTrainerBookCall,
  bffTrainerRescheduleCall,
  bffTrainerCancelBooking,
  bffSetMasterSchedule,
} from "fitbud/api";
import firebase from "fitbud/firebase";
import { range } from "lodash";
import moment from "moment";
import _ from "lodash";

export const STATUS_AVAILABLE = "available";
export const STATUS_ABNB = "not_bookable";
export const STATUS_BOOKED = "booked";
export const STATUS_CANCEL = "cancelled";
export const MAXLASTMIN = 1350;
export const GROUP_CLASS_PROVIDER = "group-classes";

export const daysNameFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const WEEK_SHORT__DAYS_NAME_MAP = {
  sunday:"sun",
  monday:"mon",
  tuesday:"tue",
  wednesday :"wed",
  thursday:"thu",
  friday:'fri',
  saturday:'sat'
};
export const diff15 = [15,30,45, 50, 60, 90];
export const diff5 = range(5, 65, 5);
export const diff1 = range(1, 13, 1);
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];


export const SESSIONS_OPTIONS = {
  GROUP_CLASS_DETAILS: 'GROUP_CLASS_DETAIL',
  SCHEDULE_VIDEO_CALL: 'SCHEDULE_VIDEO_CALL',
  CANCEL_VIDEO_CALL: 'CANCEL_VIDEO_CALL',
};


export function date2hmm(date) {
  const d = new Date(date);
  const hh = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  const mm = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  return `${hh}:${mm}`;
}
export function date2yyyymmdd(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
export function date2hhmm(date) {
  return moment(date).format('HH:mm');
}
export const hhmm2mins = (hhmm) => {
  const [hh, mm] = hhmm.split(":");
  return Number(hh) * 60 + Number(mm);
};

export const mins2hhm = (mins) => {
  if (mins >= 1440) mins = mins - 1440;
  let hh = Math.floor(mins / 60);
  let mm = mins % 60;
  hh = hh < 10 ? "0" + hh : hh;
  mm = mm < 10 ? "0" + mm : mm;
  return `${hh}:${mm}`;
};
export const mins2ampm = (mins) => {
  let suffix = mins > 720 ? "p.m" : "a.m";
  let hh = Math.floor(mins / 60);
  let mm = mins % 60;
  hh = hh < 10 ? "0" + hh : hh;
  mm = mm < 10 ? "0" + mm : mm;
  return `${hh}:${mm} ${suffix}`;
};


export function convertToSlotTime(startTime, endTime) {
  return [formatAMPM(startTime), formatAMPM(moment(endTime).add(1, 'seconds'))];
}
function formatAMPM(date) {
  return moment(date).format('hh:mm a');
}
export function reduceWeek(data){
  if(!data) return data;
  const days = Object.keys(data)
  let out = {};
  days.forEach((dayName) => {
    let slots = data[dayName]
    slots = (slots || []).map(slot=>_.omit(slot, ['index']));
    if(!slots.length) return out[dayName] = slots;  // when no slots on that day
    out[dayName] = reduceSlots(slots);
  })
  return out;
}

const reduceSlotsArray = (slots) =>{
  let sorted = slots.sort((a, b) => hhmm2mins(a.time) - hhmm2mins(b.time))
  const out = [];
  sorted.forEach((slot, index) => {
    if(index === 0) return out.push(slot);
    const lastPos  = out.length - 1;
    let lastSlot = out[lastPos];
    let lastEndtime  = hhmm2mins(lastSlot.time) + lastSlot.duration;
    let slotEndtime = hhmm2mins(slot.time + slot.duration);
    let slotStartTime = hhmm2mins(slot.time);
    if(slotStartTime > lastEndtime) return out.push(slot)
    // If slot is overlapping with lastSlot
    if (slotEndtime < lastEndtime) return;  // if this slots ends at same or before last slot's end time
    out.splice(lastPos, 1, {time:lastSlot.time, duration: lastSlot.duration + (slotEndtime - lastEndtime), location : lastSlot?.location || "" , service: lastSlot?.service || "" }); // add the difference of time between slots
  })
  return out;
}

export const getSlotsByService = (slots, includeIndex = true) =>{
  const _slots = [...slots];
  return _slots.reduce((acc,curr)=>{
    const service = curr?.service || "all";
    const key = service;
    acc[key] = acc[key] || [];
    const out= {...curr}
    acc[key].push(out);
    return acc;
  },{})

};


export const getSlotsByServiceAndLocation = (slots) =>{
  const _slots = [...slots];
  return _slots.reduce((acc,curr)=>{
    const service = curr?.service || "all";
    const location = curr?.location || "all";
    const key = service + ":" + location;
    acc[key] = acc[key] || [];
    acc[key].push(curr);
    return acc;
  },{})
}

export const reduceSlotsV2 = (slots, includeIndex) =>{
  if(!slots || !slots.length) return slots;
  const map = getSlotsByServiceAndLocation(slots);
  const outMap = {};
  _.toPairs(map).forEach((values)=>{
    const key = values[0];
    const slots = values[1];
    let out = reduceSlotsArray(slots);
    if(includeIndex) out = out.map((s,i)=>({...s, index:i}))
    outMap[key] = out;
  })
  const allSlots = _.values(outMap).reduce((acc,curr)=>[...curr,...acc], []);
  const allSortedSlots = allSlots.sort((a, b) => hhmm2mins(a.time) - hhmm2mins(b.time));
  return allSortedSlots;
};


export function reduceSlots(slots, includeIndex = false){
  return reduceSlotsV2(slots, includeIndex);
  // let sorted = slots.sort((a, b) => hhmm2mins(a.time) - hhmm2mins(b.time))
  // const out = [];
  // sorted.forEach((slot, index) => {
  //   if(index === 0) return out.push(slot);
  //   const lastPos  = out.length - 1;
  //   let lastSlot = out[lastPos];
  //   let lastEndtime  = hhmm2mins(lastSlot.time) + lastSlot.duration;
  //   let slotEndtime = hhmm2mins(slot.time + slot.duration);
  //   let slotStartTime = hhmm2mins(slot.time);
  //   if(slotStartTime > lastEndtime) return out.push(slot)
  //   // If slot is overlapping with lastSlot
  //   if (slotEndtime < lastEndtime) return;  // if this slots ends at same or before last slot's end time
  //   out.splice(lastPos, 1, {time:lastSlot.time, duration: lastSlot.duration + (slotEndtime - lastEndtime)}); // add the difference of time between slots
  // })
  // return out;
}

export const getAvailabilityState = (data,gcEnabled) =>{
  if (_.isEmpty(data)) return {};
  if (!!gcEnabled) return {...data};
  //if not then remove services and merge slots.
  const { week } = data;
  const weekConfig = {};
  _.keys(week).forEach((w) => {
    let slots = _.cloneDeepWith(_.get(week, w));
    //pick only duration and time.
    slots = slots.map((s) => _.pick(s, ['time', 'duration']));
    slots = reduceSlotsArray(slots);
    weekConfig[w] = slots;
  });
  const out = { week: weekConfig };
  return out;
};

export const getSpecificHrsState = (data,gcEnabled) =>{
  if(!!gcEnabled) return data;
  let slots =  data.map((s) => _.pick(s, ['time', 'duration']));
  slots = reduceSlotsArray(slots);
  return slots;
}

export const structureInstance = (instance) =>{
  const out = {...instance};
  out.id = instance._id;
  out.book_id = instance._id;
  out.duration = instance.duration;
  out.startDate = moment.utc(instance.time, "YYYYMMDDHHmm").local().toDate();
  out.endDate = moment(out.startDate).add(out.duration, "m").toDate();
  out.status = STATUS_BOOKED;
  out.provider = GROUP_CLASS_PROVIDER;
  out.slot = convertToSlotTime(out.startDate, out.endDate);
  out.user={};
  return out;
}

export const convertInstancesToBooking = (instances) =>{
  const out = [];
  (instances || []).forEach((instance)=>{
    const ins = structureInstance(instance);
    out.push(ins);
  })
  return out;
}

export  const sortByStartDate = (bookings) =>{
  const out = bookings.sort((a, b) => moment(a.startDate).unix() - moment(b.startDate).unix());
  return out;
}

export const getIndentationFactor = (bookings)=>{
  const out = [];
  let lastDate;
  let lastBooking;
  for(let i = 0 ;  i < bookings.length ; i++){
    const booking = bookings[i];
    if(!booking.book_id){ 
      out.push(booking)
      continue; // ie is it is availability...
    }
    let date = moment(booking.startDate).format("YYYYMMDD");
    if(date !== lastDate){//ie new day starting...
      lastDate = date;
      lastBooking = null;
      out.push(booking)
      continue;
    }else{
      if(lastBooking){
        if(moment(booking.startDate).diff(moment(lastBooking.startDate), "minutes") < 10){
          booking.indentation = (lastBooking.indentation || 0) + 1;
        }
      }
      lastBooking= booking;
      out.push(booking)
    }
  }
  return out;
}

export const structureAppointment = (slot, index, {withTimestamp = false, ignoreBooked = false, includeCancelled=false}) => {
  // withTimestamp detects firebase timestamps
  if(!slot || (!includeCancelled && slot.status === STATUS_CANCEL) || slot.status ===  STATUS_ABNB || (ignoreBooked && slot.status ===  STATUS_BOOKED )) return;
  let result = {
    id: `${index + 1} - ${new Date(withTimestamp ? slot.time.toDate().toISOString() : slot.time)}`,
    startDate: new Date(withTimestamp ? slot.time.toDate() : slot.time),
    endDate: (slot.end_time && new Date(new Date(slot.end_time) - 1000)) || undefined, // subtract 1 second to prevent overlapping
    status: slot.status,
    duration: slot.slot_duration,
    note: slot.note || '',
    book_id : slot.book_id,
    service:slot?.service,
    location_offline : slot?.location_offline,
    location_online : slot?.location_online,
    title : slot?.title,
    class : slot?.class
  };
  if (slot.status === STATUS_BOOKED) {
    result.id = `${index + 1} - ${slot.book_id}`;
    result.duration = slot.duration;
    result.endDate = moment(withTimestamp ? slot.time.toDate() : slot.time).add(slot.duration, 'minutes').subtract(1, 'seconds').toDate();
    result.slot = convertToSlotTime(result.startDate, result.endDate);
    result.trainerTs = (slot.trainerTs && slot.trainerTs.toDate()) || undefined;
    result.clientTs = (slot.clientTs && slot.clientTs.toDate()) || undefined;
    result.trainer_id = slot.trainer_id
    result.isTrainerBooking = slot.isTrainerBooking;
    result.provider = slot.provider;
    result.isIntroCall = slot.isIntroCall;
    result.user = {
      name: slot.user_name,
      image: slot.user_image,
      email: slot.user_email,
      uid: slot.uid,
    };
  }
  return result;
}
// Convert availability data to AppointemnetModel
export function convertToAppointments(slots, { withTimestamp = false, ignoreBooked = false }) {
  let out = [];
  slots.forEach((slot, index) =>{ 
   let structredSlot =  structureAppointment(slot, index, {withTimestamp, ignoreBooked})
   if (structredSlot) out.push(structredSlot)
  });
  return out;
}
export function findBookingStatus(slot) {
  if (slot.status !== "booked") return slot.status;

  let status = "upcoming";
  const { startDate, endDate, trainerTs, clientTs, location_offline } = slot;
  const isGroupClass = !!slot.class;
  const currentTime = new Date(Date.now()).valueOf();

  const diff = startDate.valueOf() - currentTime.valueOf(); //diff in epoch
  const slotEndDiff = endDate.valueOf() - currentTime.valueOf();

  if (slotEndDiff && slotEndDiff < 0) {
    if((isGroupClass || !!location_offline) && clientTs) return "completed"; //in case of classes, we have only client's join ts.
    else if (clientTs && trainerTs) return  "completed"; // When the slot has passed away and no one joined
    return (status = "missed");
  }
  if (diff && diff < 0 && slotEndDiff && slotEndDiff > 0) return (status = "ongoing");

  return status;
}
/* ---------------------
    FIREBASE FUNCTIONS 
 -----------------------*/
async function getToken(){
  let token = null;
  token = await firebase.auth().currentUser.getIdToken()
  return token;
}

export async function loadAvailability(cid, startDate, endDate, trainerId) {
  let token = await getToken();
  const res = await bffTrainerAvailability(token, cid, startDate, endDate, trainerId);
  if (res) {
    return res.data;
  } else {
    throw new Error('Something went wrong with the network call');
  }
}
export async function rescheduleBooking(cid, { book_id, newSlot, duration, serviceId, locationId, checkPurchases }) {
  let token = await getToken();
  return bffTrainerRescheduleCall(token, cid, book_id, newSlot, duration).then((res) => {
        if (res.data) {
          return res.data;
        } else {
          throw new Error('Something went wrong with the network call');
        }
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
}
export async function makeBooking(cid, { uid, time, duration, isTrainerBooking, userProfile, trainer_id, serviceId, locationId }) {
  let token = await getToken();
  duration = parseInt(duration, 10);
  return bffTrainerBookCall(token, cid, uid, time, duration, isTrainerBooking, userProfile, trainer_id, serviceId, locationId).then((res) => {
        if (res.data) {
          return res.data;
        } else {
          throw new Error('Something went wrong with the network call');
        }
      })
      .catch((err) => {
        if(!_.isEmpty(err.response.data)) return err.response.data
        console.log(err);
        return null;
      });
}
export async function cancelBooking(cid, time, book_id) {
  time = time.toISOString();
  let token = await getToken();
  return bffTrainerCancelBooking(token, cid, time, book_id).then((res) => {
        if (res.data) {
          return res.data;
        } else {
          throw new Error('Something went wrong with the network call');
        }
      })
      .catch((err) => {
      console.log(err);
      return err;
    });
}
export async function setDaySchedule(cid, date, slots, noCancel, uid) {
  let token = await getToken();
  return bffSetDaySchedule(token, cid, date, slots, !noCancel, uid).then((res) => {
        if (res.data) {
          return res.data;
        } else {
          throw new Error('Something went wrong with the network call');
        }
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
}
export async function saveMasterSchedule(cid, data, uid) {
  let token = await getToken();
    return bffSetMasterSchedule(token, cid, data, uid).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        throw new Error('Something went wrong with the network call');
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}
export async function fetchWeekData(cid, startTime, endTime,  trainerId=''){
  let start = moment(startTime).toISOString();
  let end = moment(endTime).toISOString();
  const res = await loadAvailability(cid, start, end, trainerId, 'trainer');
  return res;
}

export const freqFromPlanStart = (startDate, bookingDate, frequency) => {
  // Returns the no.of weeeks / month passed from the plans startDate to the bookingDate
  let bookDate = moment(bookingDate);
  let plan_start = moment(startDate, 'YYYYMMDD');
  let freqDays = frequency === 'week' ? 7 : 30;
  let daysDiff = bookDate.diff(plan_start, 'days');
  if (daysDiff < 0) return false; // if bookingDate is before startDate
  return Math.floor(daysDiff / freqDays); // returns 0 if date found in same week
};

export const countUserBookings = async(cid, { date, uid, startDate, frequency }) => {
  let start_date = null;
  let end_date = null;
  if (frequency === 'plan') {
    start_date = moment(startDate, 'YYYYMMDD');
  } else {
    // Calculate the week / month from startDate and query only that week / month from DB
    let freq_elapsed = freqFromPlanStart(startDate, moment(date).toDate(), frequency);
    // start_date : start of week / month eg. when startDate is 9th and we want 2nd week then will give 15th
    const daysToAdd = (frequency === 'week' ? 7 : 30) * freq_elapsed; //freq-Elapsed can be zero
    start_date = moment(startDate, 'YYYYMMDD').add(daysToAdd, 'days');
    end_date = start_date
      .clone()
      .add(frequency === 'week' ? 7 : 30, 'days')
      .endOf('day');
  }
  let query = firebase
    .firestore()
    .collection(`companies/${cid}/bookings`)
    .where('uid', '==', uid)
    .where('time', '>=', start_date.toDate());
  if (!!end_date) query = query.where('time', '<=', end_date.toDate());
  query = query.where('status', '==', 'booked').where('isTrainerBooking', '==', false);
  const bookingsSnap = await query.get();
  if (bookingsSnap.empty) return 0;
  return bookingsSnap.size;
};

export const newSlot4Add = (dayData) => {
  const lastSlot = dayData[dayData.length - 1];
  const lastMinute = hhmm2mins(lastSlot.time) + lastSlot.duration;
  if (lastMinute > MAXLASTMIN) return; // last slot ends at or after 11:30pm
  const slot = lastMinute < 1305 ? // give an hour break from last time and then create an hour slot
    { time: mins2hhm(lastMinute + 60), duration: 60 } :
    { time: mins2hhm(lastMinute + 60), duration: 1365 - lastMinute };
  return slot;
}
export const saveCalendarSettings = (uid, config) => {
  return firebase.firestore().doc(`staff/`)
}

export const filterActiveInstances = (instances) => _.filter(instances, (instance) => !!instance.active);
